
/*
 * Booking Form
 */

$c: ".booking-form";
#{$c}
{
	margin: { left: auto; right: auto;}
	position: relative;
	z-index: 400;

	#{$c}--widget &
	{
		max-width: 472px;
	}

	&__wrapper
	{
		#{$c}--widget &
		{
			box-shadow: 0px 0px 36px rgba(0,0,0,0.1);
			border-radius: 3px;
		}
	}

	&--mobile {
		@include mq('md','min') {
			display: none;
		}
	}

	&--desktop {
		@include mq('md','max') {
			display: none;
		}
	}

	&--large {
		max-width: 100%;
		
		@include mq('md','min') {
			display: block;
		}
		@include mq('md','max') {
			display: block;
		}
	}
} 



// Rev Booking
// $subC: ".rev-booking";
// #{$subC}
// {
// 	&, a, strong, b, em, i
// 	{
// 		font-family: inherit !important;  // 😷🤢
// 	}

// 	a, strong, b, em, i
// 	{
// 		font-size: inherit !important;		// 😷🤢
// 	}

// 	#{$c}--widget &
// 	{
// 		margin: { left: auto; right: auto; }
// 		max-width: 408px;
// 	}

// 	#{$subC} &__header-title
// 	{
// 		font-family: $header-font-family;
// 		font-size: 2.5em;
// 		line-height: 1;
// 	}

// 	#{$subC}--widget &__header-title
// 	{
// 		text-align: center
// 	}


// 	// Fields
// 	&-field
// 	{
// 		// Submit
// 		&#{$subC}-field--submit
// 		{
// 			#{$subC}-field__element
// 			{
// 				@include mq('sm','max') {
// 				    padding-left: 30px;
// 					padding-right: 30px;
// 				}
// 				&:not([disabled]), &[disabled] 
// 				{ 
// 					@extend .button;
// 					@extend .button--regular;
// 					white-space: normal;
// 					max-width: none;
// 					box-shadow: none;

// 					span 				// Form "Ripple" is inheriting from .button
// 					{
// 						position: absolute;
// 						display: none;
// 					}
// 				}
// 			}
// 		}
// 	}
// }


// Modifiers ---------------

