$c: '.menu-nav';

div[data-menu-section-slug="vegan-specials-menu"] {
  display: none;
}

#{$c} {
  &__main {
    display: flex;
    min-height: 92px;
    overflow-x: auto;
    overflow-y: hidden;

    &__button {
      flex-grow: 1;
      flex: 1;
      cursor: pointer;
      position: relative;
      clip-path: polygon(0% 0%, 100% 0%, 100% 85%, 60% 85%, 50% 100%, 40% 85%, 0 85%);
      background-color: color(sunshine);
      @include mq('md', 'max') {
        text-align: center;
        min-width: 100px;
      }

      @include mq('md', 'min') {
        clip-path: polygon(0% 0%, 100% 0%, 100% 85%, 53% 85%, 50% 100%, 47% 85%, 0 85%);
      }

      .ie.v-11 & {
        min-height: 78px;
        clip-path: none;

        &:after {
          display: none;
        }

        p {
          top: 50%;
        }
      }

      p {
        font-family: $header-font-family;
        color: color(white);
        margin: 0;
        font-size: 1.3rem;
        text-align: center;
        position: absolute;
        top: calc(50% - 7px);
        left: 50%;
        transform: translate(-50%, -50%);
      }
      &--blue {
        background-color: color(rdc-primary);
      }
      &--green {
        background-color: color(rdc-green);
      }

      &--pink {
        background-color: color(rdc-pink);
      }

      &:after {
        @include css-triangle(color(orange), 'down', 16px);
        bottom: -16px;
      }

      &--green {
        &:after {
          @include css-triangle(color(green), 'down', 16px);
          bottom: -16px;
        }
      }

      &--blue {
        &:after {
          @include css-triangle(color(skyblue), 'down', 16px);
          bottom: -16px;
        }
      }

      &.open {
        z-index: 2;

        .ie.v-11 & {
          p {
            text-decoration: none;

            &:after {
              position: absolute;
              bottom: -5px;
              left: 0;
              height: 3px;
              width: 100%;
              background-color: white;
              content: '';
            }
          }
        }
      }
    }
  }

  &__sub {
    justify-content: center;
    display: none;
    background-color: #fff;
    min-height: 80px;
    position: relative;
    top: -14px;
    margin-bottom: -14px;
    z-index: 1;

    &.active {
      display: flex;
    }

    &__container {
      display: flex;
      text-align: center;

      .ie.v-11 & {
        min-height: 80px;
      }

      @include mq('991px', 'max') {
        padding: 0 25px;
        overflow-x: scroll;
        -webkit-overflow-scrolling: touch;
      }
    }

    &__button {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      padding: 0 40px;
      cursor: pointer;
      @include mq('650px', 'max') {
        min-width: 65%;
      }

      &:before {
        @include pseudo;
        width: 1px;
        height: 40px;
        top: 50%;
        left: 0;
        opacity: .2;
        transform: translate(0, -50%);
        background-color: color(black);
      }

      &:last-child {
        &:after {
          @include pseudo;
          width: 1px;
          height: 50%;
          top: 50%;
          right: 0;
          opacity: .2;
          transform: translate(0, -50%);
          background-color: color(black);
        }
      }

      p {
        text-transform: uppercase;
        margin: 0;
        font-size: 17px;
        font-weight: bold;
        white-space: nowrap;
      }

      &.active {
        p {
          &:after {
            @include pseudo;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 4px;
            background-color: color(rdc-primary);
          }
        }
      }
    }
  }
}