$c: '.dots';

#{$c} {
	list-style: none;
	margin: 0;
	padding: 0;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	&__single {
		padding: 10px;
		cursor: pointer;
		opacity: .3;
		transition: $default-timing $default-easing;

		@include mq('768px','min') {
			margin: 12px 0;
			padding: 15px;
		}

		&:before {
			@include pseudo;
			position: relative;
			width: 10px;
			height: 10px;
			background-color: color(rdc-red);
			border-radius: 100%;

			@include mq('768px','min') {
				width: 15px;
				height: 15px;
			}
		}

		&:hover, &.active {
			opacity: 1;
		}
	}
	&__arrow {
		cursor: pointer;
		padding: 10px;
		color: color(rdc-red);
	}
}