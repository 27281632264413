$c: '.useful-info';

#{$c} {
	@extend .rdc-modal;
	background-color: color(rdc-background);

	/* Uncomment to show menu modal on load  */
	// opacity: 1;
	// visibility: visible;

	// > * {
	// 	opacity: 1;
	// 	visibility: visible;
	// 	transform: translateY(0);
	// }
	/* END show modal  */

	&__container {
		width: 1000px;
		max-width: 100%;
		padding: 93px 50px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		@media (min-width: 700px) {
			padding: 130px 50px;
		}

		@media (min-width: 768px) {
			padding: 41px 50px;
			max-width: 800px;
		}

		@media (min-width: 768px) and (max-height: 699px) {
			padding-top: 82px;
			display: flex;
			align-items: center;
			flex-direction: column;
			justify-content: center;
		}
		/* h3, p, a {
			color: color(white);
		} */
		a {
			font-weight: bold;
		}
	}
	&__content {
		padding-bottom: 30px;
		text-align: center;

		p:not(:last-of-type) {
			margin-bottom: 20px;
		}

		a {
			color: color(rdc-red);
		}
	}

	&.active {
		@include modalActive($c);
	}
}