$c: '.finger-prompt';

@keyframes swipe-x {
	0% {
		transform: translateX(0px)rotateZ(0deg);
	}
	66% {
		transform: translateX(-10px)rotateZ(-2deg);
	}
}

#{$c} {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, calc(-50% + 10px));
	pointer-events: none;
	opacity: 0;
	visibility: hidden;
	transition: transform .6s $default-easing, box-shadow .6s $default-easing .1s;
	background-color: rgba(6, 198, 201, 0.8);
	border-radius: 50%;
	padding: 5px;
	box-shadow: 0px 7px 10px -10px rgba(0,0,0,0.4);

	.packages & {
		top: calc(50% - 50px);
		left: calc(50% - 20px);
	}
	.conveyor & {
		left: calc(50% - 20px);
	}

	svg {
    	overflow: visible;

		path {
			fill: color(white);
			stroke: none;
		}
	}

	.swipe-x {
		top: 3px;
    	animation: swipe-x 1.25s ease-in-out backwards;
    	animation-iteration-count:infinite;
  	}

	&.active {
		opacity: 1;
		visibility: visible;
		transform: translate(-50%, -50%);
		box-shadow: 0px 27px 20px -15px rgba(0,0,0,0.2);
	}

	// &__gesture {
	// 	fill: color(orange);
	// }

	// &__hand {
	// 	transform: rotate(-10deg);
	// 	transform-origin: center;
	// }

	// &__path {
	// 	fill: none;
	// 	stroke: none;
	// }

	// &__arrowhead {
	// 	position: absolute;
	// 	pointer-events: none;
	// 	background-image: url(../../app/img/svg/arrow-head.svg);
	// 	top: -5px;
	// 	left: 0px;
	// 	width: 12px;
	// 	height: 15px;
	// }

	// &__arrowtail {
	// 	position: absolute;
	// 	pointer-events: none;
	// 	background-image: url(../../app/img/svg/arrow-tail.svg);
	// 	top: 0px;
	// 	left: 9px;
	// 	width: 0;
	// 	height: 7px;
	// }
}