.content_block {
  height: 100%;
  min-height: 500px;
  max-height: 800px;

  &.no-image {
    height: fit-content;
    min-height: fit-content;
    max-height: fit-content;
  }

  &_wrapper {
    display: flex;
    height: 100%;
    align-content: center;
    align-items: center;

    @include mq('750px', 'max') {
      &, &.right {
        flex-direction: column !important;
      }
    }

    &.right {
      flex-direction: row-reverse;
    }

    &.background-position {
      position: relative;
      justify-content: center;
    }

    &_image {
      position: relative;
      width: 50%;
      height: 100%;
      background-position: 50% 50%;
      background-size: cover;
      background-repeat: no-repeat;
      transform: translate3d(0px, 0px, 0px);
      z-index: 1;

      @include mq('750px', 'max') {
        width: 100%;
      }

      &.background-position {
        position: absolute;
        width: 100%;
        top: 0;
        height: 100%;
        bottom: 0;
        right: 0;
        left: 0;

        .content_block_wrapper_image_backdrop {
          background-color: rgba(0, 0, 0, 0.6);
        }
      }

      &_backdrop {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 1;
        background-color: rgba(0, 0, 0, 0.2);
      }
    }

    &_content {
      height: 100%;
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: color(rdc-background);
      position: relative;
      padding: 10px 0;

      @include mq('750px', 'max') {
        width: 100%;
      }

      &.background-position {
        width: 100%;
      }

      &.no-image {
        width: 100%;
          padding: 20px 0;
        @include mq('400px', 'min') {
          padding: 40px 0;
        }
        @include mq('800px', 'min') {
          padding: 50px 0;
        }
        @include mq('1400px', 'min') {
          padding: 100px 0;
        }
      }

      .leaf-background {
        position: absolute;
        z-index: 0;
        opacity: 0.4;
        background-size: contain;
        background-repeat: no-repeat;
        height: 100%;
        width: 75%;

        &.leaf-1 {
          background-image: url(../../app/img/bg/leaf-1.png);
          background-position: top -30px left -80px;
          top: 0;
          left: 0;
        }

        &.leaf-2 {
          background-image: url(../../app/img/bg/leaf-2.png);
          background-position: bottom 0 right 0;
          bottom: 0;
          right: 0;
        }

        &.leaf-3 {
          background-image: url(../../app/img/bg/leaf-3.png);
          background-position: top 0 right 0;
          top: 0;
          right: 0;
        }
      }

      &_text {
        width: 75%;
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 1;

        @include mq('750px', 'max') {
          width: 85%;
        }

        &.background-position {
          color: #fff;

          .content_block_wrapper_content_text_button {
            background-color: color(rdc-red);
          }
        }

        * {
          text-align: center;
        }

        ul {
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        &_title {
          margin-bottom: 32px;
        }

        &_button {
          min-width: 250px;
          margin: 32px 0 18px;
          background-color: color(rdc-primary);
          padding: 15px 30px;
          text-decoration: none;
          cursor: pointer;

          h5 {
            margin: 0;
            color: #fff;
            font-family: $body-font-family;
          }
        }
      }
    }
  }
}