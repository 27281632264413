.event-details {
  background-color: color(rdc-background);
  color: color(rdc-primary);
  .event {
    padding: 30px 0;

    .background-leaf {
      position: absolute;
      z-index: 0;
      opacity: 0.4;
      background-size: contain;
      background-repeat: no-repeat;

      &.leaf-1 {
        background-image: url(../../app/img/bg/leaf-1.png);
        background-position: top -30px left -80px;
        top: 0;
        left: 0;
        height: 70%;
        width: 50%;
        @include mq('lg', 'max') {
          display: none;
        }
      }
      &.leaf-3 {
        background-image: url(../../app/img/bg/leaf-3.png);
        background-position: top 0 right -80px;
        top: 0;
        right: 0;
        height: 70%;
        width: 50%;
        opacity: 0.2;
        @include mq('lg', 'max') {
          height: 100%;
          width: 100%;
        }
      }
    }
    @include mq('600px', 'min') {
      width: 85%;
      max-width: 1300px;
      margin: 0 auto;
    }

    &__image {
      position: relative;
      z-index: 1;
      width: 100%;
      height: auto;
      max-height: 300px;
      object-fit: contain;
    }

    &__details {
      position: relative;
      z-index: 1;
      padding: 20px 20px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 95%;
      margin: 0 auto;

      @include mq('600px', 'min') {
        max-width: 80%;
      }

      &__title {
        text-transform: uppercase;
        margin-bottom: 0;
      }

      &__title, &__dates {
        text-align: center;
      }

      &__description {
        margin-top: 20px;
        text-align: center;
      }

      &__terms {
        margin-top: 20px;
      }

      a {
        margin-top: 20px;
        text-align: center;
        padding: 16px 14px;
        border: none;
        cursor: pointer;
        text-decoration: none;
        text-transform: uppercase;
      }
    }
  }

  .packages {
    &__heading .subheading {
      text-transform: uppercase;
    }
    .view-all {
      display: flex;
      justify-content: center;
      padding: 80px 0 40px;
      a {
        text-align: center;
        padding: 16px 14px;
        border: none;
        cursor: pointer;
        text-decoration: none;
        text-transform: uppercase;
      }
    }
  }
}