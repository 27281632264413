.content_block_wrapper_content {
  .content_block_wrapper_text_synopsys {
    a {
      color: color(rdc-red);
    }
  }
}
.terms-and-conditions {
  details {
    margin-bottom: 10px;

    summary {
      h5 {
        display: inline;
        font-size: 1.5rem;
      }
    }

    .terms-content {
      padding-left: 20px;

      a {
        color: color(rdc-red);
      }

      ul {
        padding-left: 0;
      }

      li > ul {
        padding-left: 10px;
      }

      li:has(ul) {
        list-style: none;
      }
    }
  }
}
