.event-card {
  border: 1px solid #cecece;
  background-color: #fff;
  min-width: 300px;
  max-width: 400px;
  width: 100%;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include mq('820px', 'min') {
    max-width: 400px;
  }

  p {
    word-break: break-word;
  }

  img {
    height: 280px;
    min-width: 300px;
    max-width: 400px;
    width: 100%;
    object-fit: cover;
  }

  &__body {
    padding: 0 20px;
    margin-bottom: auto;

    &__location {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;

      .venue {
        text-transform: uppercase;
      }
    }

    .title {
      text-align: left;
      text-transform: uppercase;
      margin: 0;
      padding: 10px 0;
    }

    .description {
      text-align: left;
      margin: 10px 0 20px;
      font-size: 16px;
    }
  }

  .cta {
    padding: 0 20px;
    display: flex;
    justify-content: center;

    .button {
      max-width: 100%;
    }
  }
}
