$c: '.conveyor-item';

#{$c} {
	display: flex;
	border-radius: 3px;
    overflow: hidden;

	&__container {
		position: relative;
//		padding: 35px;
		padding-bottom: 35px;
		display: flex;
		flex-direction: column;
		align-items: center;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		width: 100%;
		.ie.v-11 & {
			display: block;
		}
	}

	// &__foreground {
	// 	margin-bottom: 25px;
	// }

	.button {
		text-transform: uppercase;
		margin-top: auto;
		@include mq('768px','max') {
			width: auto;
		}
	}
}