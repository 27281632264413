$c: '.page-contact';

#{$c} {
	&__wrapper
	{
		padding-top: 150px;
		flex-grow: 1;
	}

	&__row {
		h5 {
			margin: 0 0 1em;
		}

		a,
		a:visited,
		a:hover {
			color: color(red);
			cursor: pointer;
		}
		&__form,
		&__sidebar {
			margin: 40px auto 0;
		}

		@include mq('1025px', 'min') {
			&__sidebar {
				display: flex;
				justify-content: flex-end;
			}
		}
		@include mq('1025px', 'max') {
			flex-direction: column;

			&__form,
			&__sidebar {
				width: 80%;

				@include mq('900px', 'max') {
					width: 85%;
				}

				@include mq('650px', 'max') {
					width: 100%;
				}
			}

			&__sidebar {
				.sidebar {
					width: 100%;
				}
			}
		}
	}
}