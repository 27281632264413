$c: '.blog-post';
$package-offset: '0px';
$base-time: 250ms;
#{$c} {
	position: relative;
	transform: translate(0, 30px);
	transition: transform $base-time*2 ease-in-out;
	display: flex;
	flex-grow: 0;
	padding: 0;
	text-decoration: none;
	min-height: 295px;
	margin-bottom: 40px;

	@include mq('768px','min') {
		padding: 10px 20px;
	}

	@include mq('992px','min') {
		padding: 10px 20px;

		&:nth-child(3n+1) {
			padding-left: 0;
		}

		&:nth-child(3n+3) {
			padding-right: 0;
		}

		&:nth-child(1n+4) {
			padding-top: 30px;
		}
	}



	.owl-stage & {
		padding: 0;
		flex-grow: 1;
	}

	&:hover {
		&.inview {
			#{$c} {
				&__container {
					box-shadow: 0px 10px 26px 0px rgba(0,0,0,.28);
				}
			}
		}
	}

	&__container {
		background-color: color(rdc-primary);
		position: relative;
		margin-top: #{$package-offset};
		display: flex;
		justify-content: space-between;
		flex-direction: column;
		align-items: center;
		max-width: 100vw;
		border-radius: 3px;
		background-position: 0px 50px;
		background-size: cover;
		background-repeat: no-repeat;
		width: 100%;
		height: 100%;
		box-shadow: 0px 0px 0px 0px rgba(0,0,0,.28);
		opacity: 0;
		transition: background-position $base-time*2 ease-in-out, opacity $base-time*2 ease-in-out, box-shadow $base-time ease-in-out;
		&.owl-lazy {
			transition: background-position $base-time*2 ease-in-out, opacity $base-time*2 ease-in-out, box-shadow $base-time ease-in-out !important;
		}
	}
	&__img{
		@include mq('1600px','max') {
			max-height: 450px!important;
		}
		@include mq('1400px','max') {
			max-height: 350px!important;
		}
		@include mq('1025px','max') {
			max-height: 300px!important;
		}
		@include mq('764px','max') {
			max-height: 250px!important;
		}
	}
	&__image {
		position: relative;
		top: -#{$package-offset};
		display: flex;
	}

	&__tag {
		position: absolute;
		bottom: 0;
		right: 0;

		@include mq('md','min') {
			transform: translate(50%, 50%);
		}
	}

	&__popular {
		position: absolute;
		top: 0;
		left: 50%;
		opacity: 0;
		transform: translate(-50%, -50%);
		transition: all $base-time*1.5 $cartoon-easing $base-time*1.5;


		@include mq('md','min') {
			left: 0;
			transform: translate(0%, -50%);
		}

		width: 100%;
		.tag {
			width: fit-content;
			height: fit-content;
			min-width: 250px;

			&--flair {
				position: relative;
				color: #000;
				top: 0;
				left: 0;
				font-size: 18px !important;
				transform: rotate(0);
				font-family: $special-font-family-alt;
			}
		}
	}

	&__content {
		height: fit-content;
		width: 100%;
		background: color(rdc-primary);
		color: color(white);
		box-shadow: 0 0 0 0 rgba(0,0,0,.28);
		overflow: hidden;
		border-radius: 2px;
		padding: 15px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		margin: 0 auto 0 0;
		transform: translate(0px, 20px);
		opacity: 0;
		transition: all $base-time*2 $cartoon-easing $base-time;

		h5 {
			font-size: 1.5rem;
			margin: .5em 0;
			@include mq('md','max') {
			font-size: 1.25rem;
				line-height: 1.6rem;
			}
		}

		.content {
			@include mq('md','min') {
				max-width: 75%;
			}
		}
		p {
			margin-top: 0;
			line-clamp: 2;
		}

		.button {
			align-self: flex-start;
			margin: .5rem 0 0;
			@include mq('md','max') {
				padding-top: 10px;
			}

			span {
				font-size: 18px;
			}
		}
	}

	&.inview {
		transform: translate(0, 0px);

		#{$c} {
			transform: translate(0, 0px);

			&__container {
				opacity: 1;
				background-position: 0px 0px;
			}

			&__popular {
				opacity: 1;
			}

			&__content {
				transform: translate(0px, 0);
				opacity: 1;
			}

		}
	}

	&--lazy {
		border-radius: 3px;
		background-color: color(navy);
	}

	&--twocol {

		#{$c} {
			&__content {
				@include mq('xl','min') {
					width: 50%;
				}
			}
		}
	}
}