$c: '.christmas';

#{$c} {
	&__packages {
		order: 1;
		overflow: hidden;

		&__heading
		{
			padding-top: 0;
			padding-bottom: 0;
		}

		&__title
		{
			position: relative;
			display: inline-block;
			margin-bottom: 1em;

			span
			{
				position: absolute;
				display: inline-block;
				white-space: nowrap;
				transform: translate(-50%, -20%);
				left: 100%;
				top: 100%;
				margin-top: 0;
				margin-bottom: 10px;
			}
		}
	}


	
	// Modifiers ---------------

	&--bar #{$c}__packages
	{
		&__heading
		{
			@include mq('md', 'max')
			{
				padding: { left: 25px; right: 25px; };
			}
		}

		&__title
		{
			margin-top: 0.75em;
			margin-bottom: 0.5em;

			span
			{
				left: 0;
				top: auto;
				left: 45%;
				bottom: 100%;
				transform: translate(-25%, 50%);

				@include mq('md', 'max')
				{
					transform: translate(-15px, 50%);
				}
			}
		}
	}
}