$c: '.fiesta';

#{$c} {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 40px;
	
	@include mq('768px','min') {
		flex-direction: row;
	    justify-content: center;
		width: 100%;
	    flex: 1 100%;
	}
	
	@include mq('1024px','min') {
		flex-direction: column;
		justify-content: center;
		flex: 1 33.333333333%;
		margin-left: 40px;
	}

	&:first-child {
		@include mq('1024px','min') {
			margin-left: 0;
		}
	}

	&:nth-child(even) {
		@include mq('1024px','max') {
			.fiesta__sidebar {
				order: 1;
				margin-left: 0;
				margin-right: -60px;
			}
			.fiesta__image {
				order: 2;
			}
		}
		@include mq('768px','max') {
			.fiesta__sidebar {
				order: 2;
				margin-left: 0;
				margin-right: 0	;
			}
			.fiesta__image {
				order: 1;
			}
		}
	}

	&__sidebar {
		order: 2;
		padding: 0 25px;
		position: relative;
		top: 0;
		margin-top: -100px;

		@include mq('768px','min') {
			margin-top: 0;
			padding: 0;
			margin-left: -60px;
		}

		@include mq('1024px','min') {
			top: 0;
			padding: 0 25px;
			margin-left: 0;
			margin-top: -50%;
			left: 50%;
			transform: translate(-50%, 0%);
		}

		@include mq('1400px','min') {
			margin-top: auto;
			order: 1;
			padding: 0;
			position: absolute;
			top: 50%;
			transform: translate(0, -50%);
			z-index: 1;
			left: 0px;
		}

		.sidebar--text {
			display: flex;
			flex-direction: column;
			justify-content: space-evenly;

			.sidebar__title {
				h4 {
					margin-top: 1rem;
					text-transform: uppercase;
				}
			}
		}

	}

	&__image {
		order: 1;
		.ie.v-11 & {
			width: 100%;
		}

		@include mq('1400px','min') {
			order: 2;
			padding-left: 4vw;
		}

		img {
			@include mq('768px','min') {
				object-fit: cover;
				height: 100%;
			}
		}
	}
}