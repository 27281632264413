$c: '.rdc-fixr-modal';

#{$c} {
	@extend .rdc-modal;
	background-color: color(navy);

	/* Uncomment to show menu modal on load  */
	// opacity: 1;
	// visibility: visible;

	// > * {
	// 	opacity: 1;
	// 	visibility: visible;
	// 	transform: translateY(0);
	// }
	/* END show modal  */

	&__close {
		position: fixed !important;
		right: 0 !important;
		top: 100px !important;
	}

	&__container {
		width: 1000px;
		max-width: 100%;
		padding: 93px 50px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		position: absolute;
		top: 100px;

		@media (min-width: 700px) {
			padding: 130px 50px;
		}

		@media (min-width: 768px) {
			padding: 41px 50px;
			max-width: 650px;
		}

		@media (min-width: 768px) and (max-height: 699px) {
			padding-top: 82px;
			display: flex;
			align-items: center;
			flex-direction: column;
			justify-content: center;
		}

		h3, p, a {
			color: color(white);
		}
		a {
			font-weight: bold;
		}
	}
	&__content {
		padding-bottom: 30px;
	}

	&.active {
		@include modalActive($c);
	}
}