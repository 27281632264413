.page-events__wrapper {
  .hero__slide {
    min-height: 400px;
  }

  .event-filters {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 70%;
    margin: 40px auto;
    gap: 10px;
    .input-group {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 45%;

      label {
        text-transform: uppercase;
        margin-bottom: 4px;
      }

      input, select {
        height: 35px;
        width: 100%;
        padding: 5px 10px;
      }
    }
    button {
      width: 40%;
      padding: 8px 14px;
      border: none;
      cursor: pointer;
    }
  }

  .events-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 20px;
    max-width: 1240px;
    margin: 0 auto 20px;

    @include mq('1240px', 'max') {
      justify-content: center;
    }

    &.no-results {
      justify-content: center;
    }
  }
}
