$c: '.blog';

#{$c} {
	@include mq('lg','max') {
		padding-top: 0;
		padding-bottom: 0;
	}
	&__main {
		@include mq('lg','max') {
			order: 1;
		}
	}

	&__sidebar {
		@include mq('lg','max') {
			order: 0;
		}
	}

	&__title {
		&__header {
			font-size: 1.313rem;
			> span {
				text-transform: uppercase;
				font-size: 1rem;
				margin-top: -25px;
				margin-left: -5px;
			}
			@include mq('sm','min') {
				font-size: 2.188rem;
				> span {
					font-size: 1.4rem;
					margin-top: -45px;
				}
			}
			@include mq('md','min') {
				> span {
					margin-top: -45px;
				}
			}
		}
	}

	&__info {
		@include mq('md','max') {
			padding: 0;
		}
	}
	&__share {
		&.has-tooltip {
			.tooltip {
				&__content {
					position: absolute;
					top: 40px;
					right: 15px;
					left: auto;
				    margin-bottom: 0;
				    transform: translateY(100%);
				    width: auto;
				    &--blog {
				    	&:after {
					    	border-bottom-color: color(white);
					    	left: auto;
					    	right: 10px;
					    }
				    }
				    .tooltip {
						&__content {
							display: none;
						}
					}
				}
				&__text {
					background-color: color(white);
					padding: 10px;
					border-color: color(white);
					font-family: $body-font-family;
					font-size: 16px;
					white-space: nowrap;
					font-style: italic;
					font-weight: bold;
					border: 0;
					transform-origin: right;
				}
				&__inner,
				&__location {
					background-color: transparent;
					display: inline-block;
					border-bottom: 3px solid white;
					padding: 0;
				}
				&__location {
					opacity: 0;
					border-bottom: 0;
					display: block;
					font-size: 14px;
				}
			}
			&.active {
				.tooltip {
					&__content {
						opacity: 1;
						pointer-events: auto;
						transition-delay: 0s;
					}

					&__text {
						transition-delay: 0s;
						transform: scale3d(1,1,1);
					}

					&__inner,
					&__location {
						transition-delay: 0.1s;
						transform: translate3d(0,0,0);
						opacity: 1;
					}
					&__location {
						opacity: 0.5;
					}
				}
			}
		}
	}
	
	&__book-wrapper
	{
		.booking-form {
			display: flex;
			justify-content: center;
			.booking-form__wrapper {
				min-width: 100%;
				box-shadow: none;
			}
		}

		@include mq('md','max') 
		{
			margin: {left: auto; right: auto;}
		}
	}

	&__subscribe-wrapper
	{
		color: #fff;
		margin: {left: auto; right: auto;}
		max-width: 450px;
		text-align: center;

		ul {
			text-align: left;
		}
	}
	&__listing_allposts{
		.blog-post__content{
			min-height: 190px;
		}

	}
	&-post.col-md-6.col-lg-8{
		.content{
			max-width: 441px!important;
		}
	}
}
