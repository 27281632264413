.video-wrapper {
  padding: 30px 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &.default {
    background-color: color(rdc-background);
  }
  &.navy {
    background-color: color(rdc-primary);
  }
  &.red {
    background-color: color(rdc-red);
  }
  &.green {
    background-color: color(rdc-green);
  }

  .background-leaf {
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    position: absolute;

    @include mq('1100px', 'max') {
      display: none;
    }

    &.leaf-1 {
      background-image: url(../../app/img/bg/leaf-1.png);
      background-size: 40% !important;
      background-position: top -50px left -75px;
      opacity: 0.5 !important;
    }
  }

  .player {
    z-index: 2;
    border: none;
    height: 200px;
    @media screen and (min-width: 500px) {
      height: 400px;
      width: 90%;
    }

    @media screen and (min-width: 1000px) {
      height: 600px;
      max-width: 1200px;
    }
  }
}

.youtube {
  background-color: #000;
  margin-bottom: 30px;
  position: relative;
  padding-top: 56.25%;
  overflow: hidden;
  cursor: pointer;

  img {
    width: 100%;
    top: -16.84%;
    left: 0;
    opacity: 0.7;
  }
  .play-button {
    width: 90px;
    height: 60px;
    background-color: #333;
    box-shadow: 0 0 30px rgba( 0,0,0,0.6 );
    z-index: 1;
    opacity: 0.8;
    border-radius: 6px;
  }
  .play-button:before {
    content: "";
    border-style: solid;
    border-width: 15px 0 15px 26.0px;
    border-color: transparent transparent transparent #fff;
  }
  img,
  .play-button {
    cursor: pointer;
  }
  img,
  iframe,
  .play-button,
  .play-button:before {
    position: absolute;
  }
  .play-button,
  .play-button:before {
    top: 50%;
    left: 50%;
    transform: translate3d( -50%, -50%, 0 );
  }
  iframe {
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
  }
}