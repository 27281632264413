$c: '.christmas';

#{$c} {
	&__menus {
		&__mobile {
			order: 2;
			overflow: hidden;

			@include mq('md','min') {
				display: none;	
			}

			> .container {
				padding: 0 12px;
			}

			&__title
			{
				position: relative;
				display: inline-block;
				margin-top: 0em;
				margin-bottom: 0.75em;

				span
				{
					position: absolute;
					display: inline-block;
					white-space: nowrap;
					top: 100%;
 					left: 50%;
 					margin-top: 0;
					transform: translate(-50%, -20%);
				}
			}

			&__tab {
				@extend .bg--orange--pattern;
				flex: 1;
				text-align: center;
				padding: 45px 0;
				cursor: pointer;
				flex-basis: 100%;
				margin-bottom: 2px;

				@include mq('md','min') {
					flex-basis: auto;
					border-right: 2px solid color(white);
				}

				&:last-child {
					border-right: none;
				}

				&__title {
					color: color(white);
					font-size: 22px;
					margin: 0;
					user-select: none;
					position: relative;
					display: inline-block;
				}

				&__expand {
					width: 22px;
					height: 22px;
					background-image: url(../../app/img/svg/plus.svg);
					background-size: cover;
					position: relative;
					top: 15px;
					margin: 0 auto;
				}

				&.active {
					display: none;
				}
			}

			&__menu {
				@extend .menu-block;
				display: none;
				background-color: color(white);
				margin-bottom: 2px;
				padding: 40px 34px;
				width: 100%;
			    float: none;
			    box-shadow: none;
			    transform: none;

				&.active {
					display: table;
					 opacity: 1;
				}

				&__inner {
					@extend .menu-block__inner;
				}

				&__collapse {
					width: 22px;
					height: 22px;
					background-image: url(../../app/img/svg/minus.svg);
					background-size: cover;
					position: relative;
					top: -15px;
					margin: 5px auto 8px;
				}

				&__heading {
					text-align: center;
					display: flex;
					justify-content: center;
					align-items: center;
					flex-direction: column;

					p {
						max-width: 500px;
						font-size: 14px;
						line-height: 21px;
						font-weight: bold;
						display: inline-block;
						margin: 0;
						position: relative;
						top: -15px;
						padding: 0 10px;

						&:first-child {
							background-color: #fff;
						}

						strong {
							font-size: 22px;
							font-family: $header-font-family;
							font-weight: normal;
							position: relative;
							margin-bottom: 25px;
							display: block;

							&:after {
								content: "";
								display: block;
								position: absolute;
								width: 100%;
								height: 6px;
								bottom: -8px;
								background-image: url(../../app/img/svg/border-zig-zag.svg);
							}
						}
					}
				}

				&__container {

				}

				&__section {
					padding: 0 15px;

					&__tail {
						font-size: 14px;
						font-style: italic;
					}

					&__container {
						span {
							text-align: center;
							display: block;
							font-size: 18px;
							text-transform: uppercase;
							color: color(orange);
							font-weight: bold;
						}
					}

					&__title {
						text-align: center;
						position: relative;
						padding-top: 40px;

						&:before {
							@include pseudo;
							width: 100%;
							height: 2px;
							background-color: color(orange);
							top: 52px;
							transform: translate(0, 0%);
							left: 0;
						}

						h5 {
							font-size: 22px;
							margin: 0;
							padding: 0 10px;
							background-color: color(white);
							display: inline-block;
						}
					}
					
					&__option {
						flex: 1;

						p {
							font-size: 15px;

							strong {
								font-size: 18px;
							}
						}
					}

					&__item {
						-webkit-column-break-inside:avoid;
						-moz-column-break-inside:avoid;
						-o-column-break-inside:avoid;
						-ms-column-break-inside:avoid;
						column-break-inside:avoid;
						display: inline-block;

						p {
							font-size: 15px;

							strong {
								font-size: 18px;
							}
						}
					}
				}
			}

			&__buttons {
				padding: 0 10px;

				.button {
					max-height: 52px;
					width: 100%;
					margin-bottom: 35px;
				}

				.share__list {
					width: 100%;
					justify-content: center;
				}

				.share__container {
					max-width: 100%;
					display: block;
					margin: 35px 0 20px;
					> .button {
						margin-bottom: 0;
					}
				}

				&--after
				{
					text-align: center;
					
					.button 
					{ 
						width: auto; 
						max-width: 100%;
					}
				}
			}
		}
	}
}