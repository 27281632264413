$c: '.modal360';

#{$c} {
	@extend .rdc-modal;
	background-color: color(rdc-background);

	padding-top: 100px;
	padding-bottom: 0;

	//.no-backgroundblendmode &,
	//.safari.ipad &,
	//.iphone & {
	//	background-image: url(../../app/img/bg/orange-texture.jpg);
	//}

	/* Uncomment to show menu modal on load  */
	// background-color: color(skyblue);
	// opacity: 1;
	// visibility: visible;

	// > * {
	// 	opacity: 1;
	// 	visibility: visible;
	// 	transform: translateY(0);
	// }
	/* END show modal  */

	&__container {
		padding: 20px 30px;
		transform: translateY(0);
		height: 100vh;
		max-width: 1490px;
		@each $breakpoint, $value in $grid-breakpoints {
			@include mq($breakpoint) {
				width: map-get($container-max-widths, $breakpoint)
			}
		}


		@include mq('lg','min') {
			max-height: 80vh;
		}

		@media (min-width: 768px) and (max-height: 699px) {
			display: flex;
			align-items: center;
			flex-direction: column;
			justify-content: center;
		}

	}

	&__main {
		display: flex;
		margin-bottom: 0px;
		position: relative;
		z-index: 10;
		flex-direction: column;
		align-items: center;
		width: 100%;
		height: 100%;

		iframe {
			box-shadow: 0px 2px 32px 0px rgba(0,0,0,0.5);
			width: 100%;
			height: 100%;
			min-height: 400px;
			margin-bottom: 100px;
		}
	}

	&.active {
		@include modalActive($c);
	}
}