$c: '.christmas';

#{$c} {
	display: flex;
	flex-direction: column;
	padding-top: 0 !important;
}


// body.bar-christmas,
// body.page-christmas
// {
// 	.partydetails-component__date,
// 	.partydetails-component__time
// 	{
// 		display: none;
// 	}
// }