.masthead {
  position: relative;
  z-index: 500;
  width: 100%;
  font-family: $header-font-family;
  padding: 15px 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &.active {
    position: fixed;
    z-index: 401;

    .masthead__container .masthead__navigation {
      .js-locations-toggle, .tooltip__content {
        display: none;
      }
    }
  }

  &, .masthead__container {
    background-color: #144464;
  }

  .masthead__container {
    display: flex;
    align-items: center;
    width: 90%;

    @include mq('400px', 'max') {
      justify-content: center;
    }

    // logo
    .masthead__logo {
      margin-right: auto;

      .masthead__logo--inner--mob {
        width: 40px;
      }

      .masthead__logo--inner--desk {
        width: 250px;
      }

      @include mq('900px', 'max') {
        .masthead__logo--inner--mob {
          display: block;
        }
        .masthead__logo--inner--desk {
          display: none;
        }
      }
      @include mq('901px', 'min') {
        .masthead__logo--inner--mob {
          display: none;
        }
        .masthead__logo--inner--desk {
          display: block;
        }
      }
    }

    // logo-end

    // links
    .masthead__navigation {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;

      @include mq('600px', 'max') {
        max-width: 200px;
        flex-wrap: nowrap;
      }

      .hide-on-mobile {
        @include mq('600px', 'max') {
          display: none;
        }

      }

      .has-tooltip {
        position: relative;

        .tooltip {
          &__content {
            bottom: 0;
            margin-bottom: 0;
            transform: translateY(100%);
            @include mq('699px', 'max') {
              padding-top: 5px;
            }
          }

          &__text {
            background-color: color(rdc-green);
            padding: 20px 10px;
            font-family: $body-font-family;
            font-size: 16px;
            white-space: nowrap;
            font-style: italic;
            font-weight: bold;
            border: 0;
            width: fit-content;
          }

          &__inner,
          &__location {
            background-color: transparent;
            display: inline-block;
            border-bottom: 3px solid white;
            padding: 0;
            color: #fff;
          }

          &__location {
            opacity: 0;
            border-bottom: 0;
            display: block;
            font-size: 14px;
          }
        }

        &.active {
          .tooltip {
            &__content {
              opacity: 1;
              pointer-events: auto;
              transition-delay: 0s;
            }

            &__text {
              transition-delay: 0s;
              transform: scale3d(1, 1, 1);
            }

            &__inner,
            &__location {
              transition-delay: 0.1s;
              transform: translate3d(0, 0, 0);
              opacity: 1;
            }
          }
        }
      }

      li {
        list-style: none;
        padding: 0 8px;

        p {
          margin: 0;
        }

        a {
          color: #fff;
          text-decoration: none;
          font-weight: bold;
          padding-bottom: 4px;
          text-transform: uppercase;
          font-size: 1.5rem;

          &:hover {
            border-bottom: 2px solid #e3585c;
            color: #e3585c;
          }
        }
      }
    }

    // links end

    // burger menu
    .masthead__menu {
      margin-left: 20px;

      @include mq('800px', 'max') {
        margin-left: auto;
      }

      .burger {
        background-color: #144464;
        background-image: none;
        border-color: #fff;

        .burger__line {
          background-color: #fff;
        }
      }
    }
  }
}
