.location-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  padding: 30px 10%;
  background-color: color(rdc-primary);

  .title {
    padding-bottom: 0;
    margin: 0;
    span {
      font-size: 50px !important;
      text-wrap: wrap;
    }
  }

  &__name {
    text-transform: uppercase;
    color: color(rdc-pink);
    font-weight: lighter;
    letter-spacing: 2px;
    transform: scaleY(105%);
    font-size: 20px;
    text-align: center;
  }

  &__details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;

    @include mq('md', 'min') {
      flex-direction: row;
    }

    &__description {
      display: flex;
      flex-direction: column;
      gap: 20px;
      max-width: 600px;
      margin: 20px auto;

      .description {
        text-align: center;
      }

      .find-us {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 10px;
        @include mq('md', 'max') {
          flex-direction: column;
          align-items: center;
          text-align: center;
        }
      }

      &__group {
        a {
          color: #fff;
          text-decoration: underline;
          margin-left: 4px;
          font-size: 16px;
          cursor: pointer;
        }
        h5 {
          text-transform: uppercase;
          color: color(rdc-pink);
          font-weight: lighter;
          letter-spacing: 1px;
          transform: scaleY(105%);
          margin: 20px 0;
        }
      }
    }

    &__map {
      width: 100%;
      height: 100%;
    }
  }
}
