$c: '.christmas';

#{$c} {
	&__info {
		order: 4;
		@extend .gallery--large__container;
		@extend .bg--grey-light--pattern;
		overflow: hidden;

		@include mq('md','min') {
			order: 4;
			background-color: #064A39;
			background-size: auto;
			background-image: url(../../app/img/patterns/main_texture.jpg);
			background-repeat: repeat;
			padding-bottom: 70px;

			.no-backgroundblendmode &,
			.safari.ipad &,
			.iphone & {
				background-size: contain;
				background-repeat: repeat;
				background-image: url(../../app/img/bg/green-dark-texture.jpg);
			}
		}

		.dots__arrow {
			color: color(white);
		}

		.dots__single {
			&:before {
				background-color: color(white);
			}
		}
	}
}