$c: '.christmas';

#{$c} {
	&__intro {
		order: 2;
		@extend .bg--grey-light--pattern;
		color: color(font);
		display: flex;
		align-items: center;
		padding-top: 40px;
		padding-bottom: 60px;

		@include mq('768px','min') {
			padding-top: 120px;
			padding-bottom: 100px;
		}

		@include mq('md','min') {
			order: 1;
		}

		&--nopad {
			padding-bottom: 0 !important;
		}

		&__container
		{
			> .row
			{
				justify-content: center;
				@include mq('md','max') {
					display: block;
				}
			}
		}

		&__left {
			flex-basis: 50%;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			align-content: center;
			justify-content: center;
		}

		&__right {
			flex-basis: 50%;
			max-width: 650px;
			line-height: (33/18);
			
			@include mq('md','min') {
				padding-left: 100px;
			}

			p:first-child {	margin-top: 0; }

			ul
			{
				padding-left: 0;
			}

			li {
				list-style: none;
				margin-bottom: 0.5em;
				text-indent: -2em;
				padding-left: 2em;

				&:before
				{
					content: "•";
					display: inline-block;
					width: 0.5em;
					margin-right: 0.5em;
					color: color(rdc-red);
					text-indent: initial;
					font-size: 2em;
					line-height: 0;
					text-align: center;
					vertical-align: sub;
				}
			}
		}

		&__title {
			text-align: left;
			margin-top: 0;

			span {
				display: inline-block;
				text-align: left;
				transform: translate(0em, 0.1em);
			}
		}



		// Modifiers
		
		#{$c}--bar &
		{
			&__left {
				width: 50%;

				@include mq('md','max') 
				{
					display: block;
					width: 100%;
				}
			}
		
			&__title
			{
				text-align: center;
				max-width: 650px;				
				margin: { left: auto; right: auto; top: 1em; }

				span {
					position: absolute;
					display: inline-block;
					margin-top: 0;
					left: 50%;
					bottom: 100%;
					transform: translate(-50%, 0.25em);
				}
			}
		}
	}
}