$c: '.full-width-content';

#{$c} {
	&__title {
		display: inline-block;

		span {
			margin-top: 0;
			text-align: center;

			&.bottom {
				transform: translate(-50%, 140%);
			}
		}
	}

	&__content {
		display: flex;
		flex-direction: column;
		align-items: center;

		p {
			font-weight: bold;
			font-size: 22px;
			line-height: 31px;
			margin-top: 0;
			margin-bottom: 42px;
		}
	}

	iframe {
		max-width: 100%;
	}

	&.bg--white--pattern {
		p, span, a {
			color: #000;
		}
	}
}