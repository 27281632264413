$c: '.menu-filter';

#{$c} {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
	@include mq('768px','max') {
		flex-direction: column;
		align-items: flex-end;
		width: 100%;
	}

	> .button { 
		margin-right: 20px;
		@include mq('768px','max') {
			margin-bottom: 20px;
			margin-right: 0;
		}
	}

	&__tab {
		@extend .selectric;
		display: inline-block;
		cursor: pointer;
		z-index: 1;
		z-index: 18;
		@include mq('768px','max') {
			width: 100%;
		}

		.green & {
			border-color:rgba(color(green), .4);
		}

		.blue & {
			border-color:rgba(color(skyblue), .4);
		}

		&.active {
			border-bottom-color: transparent;
		}

		.label {
			font-family: $header-font-family;
			font-size: 1.2rem;
			padding: 0 20px;

			&:after {
				color: color(red);
			}
		}
	}

	&__container {
		@include mq('768px','max') {
			padding: 0 10px;
		}
	}

	&__options {
		width: 100%;
		background-color: color(white);
		border: 2px solid color(rdc-red);
		margin-top: -2px;
		padding: 75px 20px 40px;
		transform-origin: top;
		transform: scaleY(0);
		transition: transform $default-timing $cool-easing;
		position: absolute;
		top: 69px;
		visibility: hidden;
		opacity: 0;

		.ie.v-11 & {
			right: 13px;
		}

		@include mq('992px','max') {
			top: 77px;
			padding: 25px 20px;
		}
		@include mq('768px','max') {
			top: 127px;
		}

		.green & {
			border-color:rgba(color(green), .4);
		}

		.blue & {
			border-color:rgba(color(skyblue), .4);
		}

		&.active {
			z-index: 17;
			opacity: 1;
			visibility: visible;
			transform: scaleY(1);
			box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.1);

			> * {
				opacity: 1;
				transform: translateY(0);
				transition: transform .3s $cool-easing, opacity .3s $cool-easing .1s;
			}
		}

		> * {
			opacity: 0;
			transform: translateY(20px);
		}

		&__section {
			display: flex;
			align-items: flex-start;
			flex-flow: column wrap;
			max-width: 1100px;
			margin: 0 auto;
			padding: 25px 0;
			border-bottom: 2px solid color(red);

			.iphone.safari & {
				display: block;
			}

			@include mq('520px','min') {
				flex-flow: row wrap;
				padding: 50px 0;
			}

			&:first-child {
				padding-top: 0;
			}

			&:nth-last-child(2) {
				border-bottom: none;
			}

			> div {
				flex-basis: 75%;
				display: flex;
				flex-flow: row wrap;
			}
		}
		
		&__label {
			padding-right: 20px;
			font-weight: 700;
			margin: 0 0 20px 0;
			
			@include mq('520px','min') {
				margin: 0;
				padding-right: 20px;
				flex-basis: 25%;
			}

			@include mq('768px','min') {
				padding-right: 50px;
				padding-bottom: 30px;
			}
		}

		&__option {
			padding-top: 20px;

			flex: 0 50%;

			&:nth-child(-n+2) {
				padding-top: 0;
			}

			@include mq('lg','min') {
				flex: 0 25%;

				&:nth-child(-n+4) {
					padding-top: 0;
				}
			}

			label {
				user-select: none;
			}
		}
	}

	&__buttons {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-flow: row wrap;
	}

	&__button {
		flex-basis: 100%;
		display: flex;
		justify-content: center;
		margin-bottom: 20px;

		&:last-child {
			margin-bottom: 0;
		}

		@include mq('440px','min') {
			flex-basis: auto;
			margin-right: 20px;
			margin-bottom: 0;

			&:last-child {
				margin-right: 0;
			}
		}
	}
}