$c: '.menu';
$d: '[data-menu-container]';

#{$c} {
  @extend .rdc-modal;
  background: none;
  background-color: color(rdc-background) !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;

  .background-leaf {
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    position: absolute;

    @include mq('1100px', 'max') {
      display: none;
    }

    &.leaf-red-1 {
      background-image: url(../../app/img/bg/leaf-red-1.png);
      background-size: 25% !important;
      background-position: bottom -200px right 100px;
    }

    &.leaf-red-2 {
      background-image: url(../../app/img/bg/leaf-red-2.png);
      background-size: 17% !important;
      background-position: bottom -75px right -75px;
    }

    &.leaf-red-3 {
      background-image: url(../../app/img/bg/leaf-red-3.png);
      background-size: 25% !important;
      background-position: bottom -100px left -85px;
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
    }

    &.leaf-1 {
      background-image: url(../../app/img/bg/leaf-1.png);
      background-size: 40% !important;
      background-position: top left -75px;
      opacity: 0.5 !important;
    }
  }

  &__sharing {
    &__form {
      @include mq('1100px', 'max') {
        display: none;
      }
    }

    .booking-form {
      display: flex;
      justify-content: center;

      .booking-form__wrapper {
        min-width: 375px;
        box-shadow: none;

        .iframe-container {
          min-height: 220px !important;
        }

        @include mq('1000px','min') {
          min-width: 500px;
        }
      }
    }

    .button__group {
      display: none;

      &.active {
        display: block;
        @include mq('768px', 'min') {
          display: flex;
        }
      }
    }

    .button {
      // PDF not set in the CMS
      &[href=""] {
        display: none;
      }
    }

  }

  &__filtering {
    position: fixed;
    top: 59px;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
    z-index: -1;

    @include mq('768px', 'min') {
      position: absolute;
      top: 66px;
    }


    &.loading {
      transition: opacity .3s $cool-easing, background-color .2s $cool-easing;
      opacity: 1;
      visibility: visible;
      z-index: 19;

      &:before,
      &:after {
        transition: opacity .3s $cool-easing .1s;
        opacity: 1;
      }
    }

    &:before {
      opacity: 0;
      font-size: 14px;
      color: black;
      display: block;
      content: 'Filtering your menus...';
      position: absolute;
      top: calc(50% - 12px);
      left: 50%;
      transform: translateX(-50%);
      @include mq('768px', 'min') {
        top: calc(15vh + 24px);
      }
    }

    &:after {
      opacity: 0;
      content: '';
      position: absolute;
      top: calc(50vh - 62px);
      left: calc(50% - 12px);
      border-radius: 50%;
      width: $spinner-base-line-height;
      height: $spinner-base-line-height;
      border: .25rem solid color(grey, regular);
      border-top-color: color(black);
      animation: spinner $spinner-duration infinite linear;
      @include mq('768px', 'min') {
        top: calc(15vh - 12px);
      }
    }

  }

  &__container {
    padding: 0 30px;
    transform: translateY(0);
    width: 90%;
    position: absolute;
    top: 20%;

    @include mq('750px', 'max') {
      top: 25%;
    }

    @media (min-width: 768px) and (max-height: 699px) {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
    }

    @media (min-width: 992px) and (min-height: 600px) and (max-height: 698px) {
      .menu__main {
        a {
          font-size: 26px;
        }
      }
    }
  }

  &__main {
    display: flex;
    position: relative;
    z-index: 10;
    flex-direction: column;
    align-items: center;

    @include mq('768px', 'min') {
      flex-direction: row;
      align-items: initial;
    }

    &__nav {
      border-bottom: 1px solid rgba(color(white), .5);
      margin: 0 auto;
      padding-bottom: 40px;

      @include mq('768px', 'min') {
        text-align: left;
        border-bottom: 1px solid color(white);
        flex-grow: 1;
        padding: 20px 20px 20px 0;
        border-bottom: none;
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        margin: 0;
      }

      @include mq('850px', 'min') {
        padding: 20px 80px 20px 0;
      }

      .decuba-app {
        @include mq('700px', 'min') {
          display: none;
        }

        a {
          &:after {
            content: "";
            display: block;
            position: absolute;
            height: 4px;
            bottom: 0;
            width: 100%;
            right: 0;
            background-color: color(sunshine);
          }
        }
      }

      li {
        list-style-type: none;
        position: relative;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        margin-bottom: 6px;

        @include mq('768px', 'min') {
          justify-content: flex-start;
        }

        &:before {
          @include mq('768px', 'min') {
            content: 'f';
            font-family: $body-font-flores;
            color: color(rdc-red) !important;
            background: none !important;
            margin-right: 10px;
            font-size: 30px;
          }
        }

        &:last-child {
          margin-bottom: 0;
        }

        a {
          text-transform: uppercase;
          position: relative;
          font-family: $header-font-family;
          font-weight: bold;
          letter-spacing: 2.28px;
          color: color(font);
          font-size: 2rem;
          text-decoration: none;

          @include mq('768px', 'min') {
            padding: 0;
            text-align: left;
          }

          @include mq('750px', 'max') {
            font-size: 1.5rem;
          }
        }

        .active & {
          a {
            transform: translateX(0px);
            opacity: 1;
          }
        }

        &.current-menu-item {
          a {
            &:before, &:after {
              opacity: 1;
            }
          }
        }

        &:hover {
          a {
            color: #eb5a5e !important;
          }
        }
      }

    }

    &__venues {
      flex-grow: 1;
      margin: 0 auto;
      border-bottom: 1px solid rgba(color(white), .5);
      padding: 0 0 25px;

      @include mq('768px', 'min') {
        border-bottom: none;
        padding: 20px 0 20px 20px;
        margin: 0;
      }

      @include mq('850px', 'min') {
        padding: 20px 0 20px 80px;
      }

      &--container {
        display: flex;
      }

      h3 {
        font-family: $header-font-family;
        font-size: 2rem;
        font-weight: 400;
        text-transform: uppercase;
        text-align: center;
        letter-spacing: 2px;
        margin-top: 30px;
        transform: translateY(20px);
        opacity: 0;
        transition: transform ($default-timing*3) $cartoon-easing, opacity ($default-timing*2) $default-easing;
        transition-delay: 0ms;

        .active & {
          transition-delay: 1000ms;
          transform: translateY(0px);
          opacity: 1;
        }

        @include mq('md', 'min') {
          font-weight: 700;
          text-align: left;
          font-size: 2.25rem;
          margin-top: 0;
        }

        a {
          color: color(font);
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }

        &:before {
          @include mq('768px', 'min') {
            content: 'f';
            font-family: $body-font-flores;
            color: color(rdc-red) !important;
            background: none !important;
            margin-right: 10px;
            font-size: 30px;
            font-weight: normal;
          }
        }
      }

      ul {
        column-count: 2;
        position: relative;
        padding: 0;
        margin: 0;

        li {
          position: relative;
          cursor: pointer;
          padding-left: 20px;
          margin-bottom: 8px;
          list-style-type: none;

          &:before {
            @include pseudo;
            border-radius: 100%;
            width: 8px;
            height: 8px;
            background: color(rdc-primary) !important;
            margin-bottom: 8px;
            margin-right: 10px;
            left: 0;
            top: 50%;
            transform: scale(0) translateY(-50%);
            transform-origin: center;
            transition: transform ($default-timing*2) $cartoon-easing;
          }

          a {
            color: color(font);
            display: inline-block;
            font-family: $body-font-family;
            text-decoration: none;
            font-weight: 400;
            font-size: 16px;
            position: relative;
            transform: translateX(-15px);
            opacity: 0;
            transition: transform ($default-timing*2) $cartoon-easing, opacity ($default-timing*2) $default-easing;

            @include mq('768px', 'min') {
              font-size: 20px;
            }
          }

          &:hover {
            a {
              color: #eb5a5e !important;

              &:before, &:after {
                opacity: 1;
              }

              &:before {
                width: 100%;
              }

              &:after {
                width: 0;
              }
            }
          }

          .active & {

            &:before {
              transform: scale(1) translateY(-50%);
            }

            a {
              transform: translateX(0px);
              opacity: 1;
            }
          }
        }
      }

      &__newtag {
        position: absolute;
        left: 100%;
        bottom: 100%;
        transform: translate3d(-75%, 30%, 0) rotate(10deg);
      }
    }
  }

  &__social {
    position: relative;
    z-index: 10;
    margin: 0 auto;
    margin-bottom: 20px;

    @include mq('410px', 'min') {
      margin-bottom: 50px;
    }

    @include mq('768px', 'min') {
      margin: 0;
      margin-bottom: 0;
    }

    h3 {
      font-size: 16px;
      color: color(black);
      font-weight: 400;
      text-transform: uppercase;
      text-align: center;
      letter-spacing: 2px;
      margin-top: 30px;

      @include mq('md', 'min') {
        display: none;
      }
    }

    ul {
      padding: 0;
      margin: 0;
      text-align: center;
      margin-left: -2vw;
      display: flex;
      justify-content: center;
      align-items: center;
      @include mq('md', 'min') {
        margin-left: -30px;
      }

      li {
        display: inline-block;
        list-style-type: none;
        margin-left: 2vw;

        &:last-child {
          margin-right: 0;
        }

        @include mq('md', 'min') {
          margin-left: 30px;
        }

        a {
          min-width: 44px;
          min-height: 44px;
          display: flex;
          align-items: center;

          svg {
            margin: 0 auto;
            transform: scale(1);
            transition: transform 150ms $default-easing;
          }

          &:hover,
          &:focus {

            svg {
              transform: scale(1.1);
            }
          }
        }
      }
    }

    &--whatsapp {
      @include mq('md', 'min') {
        display: none;
      }
    }
  }

  &.active {
    @include modalActive($c);
  }

  @media (max-height: 768px) {
    display: block;
  }
}

#{$d} {
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  display: none;

  &.open {
    display: block;
    position: relative;
    visibility: visible;
    opacity: 1;
    pointer-events: auto;
  }
}

[data-menu-pattern="0"] {
  @extend .bg--orange--pattern;
}

[data-menu-pattern="1"] {
  @extend .bg--green--pattern;
}

[data-menu-pattern="2"] {
  @extend .bg--skyblue--pattern;
}