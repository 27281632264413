$c: '.video-modal';

#{$c} {
	@extend .rdc-modal;
	background-color: color(orange);
	justify-content: flex-start;

	/* Uncomment to show location modal on load  */
	// opacity: 1;
	// visibility: visible;

	// > * {
	// 	opacity: 1;
	// 	visibility: visible;
	// 	transform: translateY(0);
	// }
	/* END show modal  */
	
	&.active {
		@include modalActive($c);
	}

	.player-wistia {
		@include align(true, true, absolute);
		width: 100vw;

		@include mq('md','min') {
			width: 80vw;
		}

		&:fullscreen {
			transform: translate(0);
		}

		.wistia_embed {
			width: 640px;
			height: 360px;
			max-width:100%;
			max-height:100%;
		}

		video {
			width:100%;
			height:100%;
		}
	}
}