$c: '.standalone-form';

#{$c} {
	padding: 60px 0;
	position: relative;

	&__tint {
		background-color: rgba(0,0,0,0.05);
	}

	@include mq('md','min') {
		padding: 120px 0;
	}

	.booking-form--widget
	{
		.booking-form__wrapper
		{
			background-color: transparent;
			box-shadow: none;
			border-radius: 0;
		}
	}
}