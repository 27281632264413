.page_book {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: color(rdc-red);
  background-image: url(../../app/img/bg/green-bg.png);
  background-size: cover;
  padding: 60px 120px;
  color: #fff;

  @include mq('600px', 'max') {
    padding: 40px;
  }

  &_wrapper {
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: flex-start;
    gap: 85px;

    &_booking-form {
      width: 60%;

      &_content {
        max-width: 850px;

        h1 {
          margin-top: 0;
          font-size: 4rem;

          @include mq('800px', 'max') {
            font-size: 3rem;
          }
        }

        p {
          margin: 10px 0;
        }
      }

      .booking-form {
        margin: 30px 0 0 0;
        max-width: 700px;

        @include mq('1400px', 'max') {
          margin: 30px auto 0;
        }
      }
    }

    &.no-location {
      .page_book_wrapper_booking-form {
        width: 100%;
      }

      h1, p {
        text-align: center;
      }

      .booking-form {
        max-width: 100%;
      }
    }

    &_location-times {
      min-width: 500px;
      width: 100%;
      max-width: 530px;

      @include mq('1400px', 'max') {
        margin: 0 auto;
      }

      @include mq('600px', 'max') {
        min-width: 320px;
        max-width: 100%;
      }

      .sidebar {
        width: 100%;
        background: #fff;
        color: #000;
        padding: 30px 40px;

        &--location__address h5 {
          margin-top: 0;
        }
      }
    }

    @include mq('1400px', 'max') {
      flex-direction: column;
      text-align: center;

      &_booking-form {
        width: 100%;
      }
    }
  }
}