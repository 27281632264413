.temporary-banner {
    display: none;
    width: 100%;
    margin-top: 12.5rem;

    @include mq('340px', 'min') {
        margin-top: 13.2rem;
    }
    @include mq('375px', 'min') {
        margin-top: 13.4rem;
    }
    @include mq('576px', 'min') {
        margin-top: 10.7rem;
    }
    @include mq('700px', 'min') {
        margin-top: 11.3rem;
    }
    @include mq('900px', 'min') {
        margin-top: 8.8rem;
    }
    @include mq('992px', 'min') {
        margin-top: 8.1rem;
    }
    @include mq('1400px', 'min') {
        margin-top: 5.1rem;
    }
    
    &--content.background-black {
        background-color: #000;
    }
    &--content.background-white {
        background-color: #fff;
    }
    &--content.background-navy {
        background-color: #12284C;
    }
    &--content {
        padding: 20px 10px;
        background-color: #000;
        
        &-text {
            p, a {
                font-size: .8rem;
                letter-spacing: 1px;
                color: #fff;
                text-transform: uppercase;
                text-align: center;
                font-family: 'cubano';
                
                @include mq('700px', 'min') {
                    font-size: 1.05rem;
                }
            }
        }
        &-text.color-white {
            p, a {
                color: #fff;
            }
        }
        &-text.color-black {
            p, a {
                color: #000;
            }
        }
        &-text.color-navy {
            p, a {
                color: #12284C;
            }
        }
    }

}

.temporary-banner.show-banner {
    display: block;
}