$c: '.sidebar';


#{$c} {
  background: color(white);
  box-shadow: 0 0 36px 0 rgba(0, 0, 0, .2);
  display: inline-block;
  overflow: hidden;
  transform: translateZ(0);
  border-radius: 2px;
  padding: 15px 40px;
  max-width: 100%;
  z-index: 2;

  .block {
    &:not(:last-of-type) {
      margin-bottom: 20px;
    }

    .title {
      text-align: left;
      color: color(rdc-primary);
      margin: 10px 0;
    }
  }

  @include mq('1024px', 'min') {
    max-width: 430px;
  }

  hr {
    margin: 20px 0;
    border: 1px solid rgba(color(navy), .2);
  }

  &--location,
  &--book {
    width: 100%;

    position: relative;
    // top: -60px;
    // margin-bottom: -60px;

    // @include mq('md','min') {
    // 	top: 0;
    // 	margin-bottom: 0px;
    // }

    @include mq('1024px', 'min') {
      width: 520px;
      max-width: 100%;
    }

    &.loading {
      > * {
        opacity: 0.25;
        position: relative;
      }

      &:after {
        content: '';
        position: absolute;
        top: calc(50% - 12px);
        left: calc(50% - 12px);
        border-radius: 50%;
        width: $spinner-base-line-height;
        height: $spinner-base-line-height;
        border: .25rem solid color(grey, regular);
        border-top-color: color(black);
        animation: spinner $spinner-duration infinite linear;

      }
    }

    &__address {
      display: block;

      h5 {
        margin: 10px 0 0;
      }
    }

    &__phone {
      display: none;

      @include mq('md', 'min') {
        display: block;
      }

      h6 {
        margin: 10px 0 20px;

        a {
          color: color(rdc-primary);
        }
      }
    }

    &__pin {
      display: none;

      @include mq('md', 'min') {
        display: block;
      }

      a {
        color: inherit;
        text-decoration: none;
        display: flex;
        align-items: center;

        .pin {
          font-size: 1.75rem;
          margin-right: 8px;
          color: color(rdc-primary);
        }

        .text {
          position: relative;
          border-bottom: 1px solid #000;
        }
      }
    }

    &__deliveroo {
      display: none;
      margin-top: 8px;

      @include mq('md', 'min') {
        display: block;
      }

      a {
        color: inherit;
        text-decoration: none;
        display: flex;
        align-items: center;

        .deliveroo {
          font-size: 1.3rem;
          margin-right: 8px;
        }

        .text {
          position: relative;
          font-style: italic;

          &:after {
            @include pseudo;
            bottom: -4px;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: color(skyblue);
          }
        }
      }
    }

    hr {
      display: none;

      @include mq('md', 'min') {
        display: block;
      }
    }

    &__times {
      @include mq('500px', 'min') {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }

      @include mq('650px', 'min') {
        justify-content: space-around;
      }

      @include mq('1025px', 'min') {
        justify-content: space-between;
      }

      &__today {
        p {
          font-size: 18px;
          font-weight: 700;
          line-height: 1.5rem;
          margin: 0 0 1em;

          @include mq('md', 'min') {
            font-size: 1.3125rem;
          }
        }
      }

      &__section {
        width: fit-content;

        &--weekdays {
          p:first-of-type {
            margin-top: 0;
          }
        }

        @include mq('520px', 'max') {
          width: 100%;

          &--weekdays {
            display: none;
          }
        }

        &:not(:last-of-type) {
          margin-bottom: .5rem;
        }

        p {
          display: flex;
          justify-content: center;
          //Remove this when we get the nice data
          line-height: 26px;
          margin: 10px 0;

          .opening-time__day {
            display: none;
          }

          @include mq('500px', 'max') {
            justify-content: space-between;

            .opening-time__day {
              display: block;
            }
          }


          .opening-time__time {
            min-width: 150px;
            display: flex;
            justify-content: space-between;

            &--end,
            &--start {
              min-width: 40px;
            }
          }

          &.title {
            &.main-title {
              margin: 0 !important;
            }

            font-weight: 600;
            text-align: center;

            .icon-caret-down {
              margin-left: 5px;

              @include mq('521px', 'min') {
                display: none
              }

              &.rotate {
                transform: rotate(180deg);
              }
            }
          }

        }

        &--happy {
          width: 100%;

          p {
            margin-top: 0;
            display: block;
            text-align: center;
          }
        }

        .food-times-accordion {
          @include mq('520px', 'max') {
            max-height: 0;
            overflow: hidden;
            transition: max-height 0.15s ease-out;

            &.active {
              max-height: 500px;
              transition: max-height 0.2s ease-in;
            }
          }
        }

        &.sidebar--location__times__section--overridden {
          width: 100%;
          display: flex;
          flex-direction: column;

          .override-content {
            display: flex;
            justify-content: space-around;
            flex-wrap: nowrap;

            &.closed {
              justify-content: space-evenly;

              .weekday {
                max-width: fit-content;
              }
            }

            @include mq('500px', 'max') {
              flex-direction: column;
            }

            .weekday {
              @include mq('500px', 'min') {
                max-width: 100px;
                width: 100%;
              }
            }

            .bar-hours,
            .kitchen-hours {
              min-width: 70px;
              @include mq('500px', 'min') {
                display: none;
              }
            }

            p {
              margin: 0;
              width: 100%;
              @include mq('500px', 'max') {
                justify-content: space-evenly;
              }
            }

            &.closed {
              justify-content: center;
            }

          }
        }
      }

      .hidden {
        display: none;
      }
    }

    &__info {
      > p {
        text-align: center;
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 15px;
      }

      &__icons {
        display: flex;
        justify-content: flex-start;
        flex-flow: row wrap;
        align-items: flex-end;
        gap: 20px;
        margin-top: 20px;

        @include mq('360px', 'max') {
          justify-content: center;
          align-items: flex-start;
        }

        &--4 {
          #{$c} {
            &--location {
              &__info {
                &__icons {
                  &__icon {
                    @include mq('460px', 'max') {
                      flex: 0 45%;
                    }
                  }
                }
              }
            }
          }
        }

        &__icon {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          font-size: 1rem;
          cursor: pointer;
          transition: transform .3s ease-out;
          transition-delay: .1s;
          border-bottom: 1px solid #000;

          span {
            color: color(rdc-primary);
          }

          @include mq('360px', 'max') {
            flex: 0 calc(50% - 10px);
            margin-left: 5px;
            margin-right: 5px;
            text-align: center;
            align-self: stretch;
          }

          span {
            font-size: 1.625rem;
            margin-bottom: 10px;
            min-height: 34px;
            display: flex;
            align-items: center;

            @include mq('360px', 'max') {
              min-height: 34px;
              display: flex;
              align-items: center;
            }

            &.icon-360 {
              &:before {
                font-size: 1.3em;
              }
            }

            &.icon-hanger {
              font-size: 2.5rem;
            }

            &.icon-disabled {
              font-size: 2.125rem;
            }
          }
        }
      }

      &__hotline {
        p {
          a {
            color: color(orange);
            font-weight: bold;
          }
        }
      }
    }

    &__social {
      display: flex;
      list-style: none;
      justify-content: center;
      flex-flow: row wrap;
      @include mq('1024px', 'max') {
        padding-top: 40px;
      }

      &__icon {
        line-height: 0;
        border-radius: 100%;
        width: 44px;
        height: 44px;
        transition: box-shadow .3s ease-out, transform .3s ease-out;
        transition-delay: .1s;
        margin: 0 10px;

        &:hover {
          box-shadow: rgba(45, 45, 45, 0.05) 0px 2px 2px, rgba(49, 49, 49, 0.05) 0px 4px 4px, rgba(42, 42, 42, 0.05) 0px 8px 8px, rgba(32, 32, 32, 0.05) 0px 16px 16px, rgba(49, 49, 49, 0.05) 0px 32px 32px, rgba(35, 35, 35, 0.05) 0px 64px 64px;
          transform: translate(0, -4px);
        }

        a {
          color: inherit;
          text-decoration: none;
          line-height: 0;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          span {
            font-size: 1.5rem;

            &.icon-twitter {
              font-size: 1.25rem;
            }
          }
        }
      }
    }

    @include mq('1023px', 'max') {
      // display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }
    @include mq('700px', 'max') {
      display: block;
    }
    // Responsive blocks
    &__block1 {

      @include mq('1023px', 'max') {
        order: 1;
        flex: 1 50%;
        padding: 10px 50px 10px 10px;
        position: relative;
        justify-content: center;
        display: flex;
        flex-direction: column;
        &:after {
          content: '';
          height: 100%;
          width: 1px;
          background-color: rgba(color(navy), .2);
          position: absolute;
          top: 0;
          right: 40px;
        }
        hr {
          display: none;
        }
      }
      @include mq('md', 'max') {
        padding: 10px;
        &:after {
          display: none;
        }
      }
    }

    &__block2 {

      @include mq('1023px', 'max') {
        order: 3;
        flex: 1 50%;
        padding: 10px;
        hr {
          display: none;
        }
      }
      @include mq('md', 'max') {
        order: 2;
      }
    }

    &__block3 {

      .icon-door {
        width: 30px;
      }

      @include mq('1023px', 'max') {
        order: 2;
        flex: 1 50%;
        padding: 10px;
        hr {
          display: none;
        }
      }
      @include mq('md', 'max') {
        order: 3;
      }
    }
  }

  &--book {
    #{$c} {
      &--location {
        &__address {
          display: block;
          color: color(rdc-primary);

          h5 {
            margin: 10px 0 0;
          }
        }

        &__phone {
          display: block;

          h6 {
            margin: 10px 0 20px;

            a {
              color: color(rdc-primary);
            }
          }
        }

        &__pin {
          display: block;
        }

        // Responsive blocks
        &__block1 {
          display: block;

          &:after {
            display: none;
          }

          @include mq('md', 'max') {
            &:after {
              display: none;
            }
            hr {
              display: none;
            }
          }
        }
      }
    }
  }

  &__title {
    h4 {
      margin-top: 0;
    }
  }

  &__description {
    max-width: 368px;
    margin: 20px 0;

    @include mq('768px', 'min') {
      margin: 20px auto 25px;
    }

    p {
      color: color(navy);
      line-height: 24px;
      margin-top: 0;
      @include mq('768px', 'min') {
        line-height: 31px;
      }
    }
  }


  &__button {
    display: flex;
    justify-content: center;
    margin-top: auto;

    a {
      @include mq('md', 'min') {
        width: 100%;
      }
    }
  }

  &--small {
    max-width: 320px;
    padding: 30px 25px !important;

    #{$c} {
      &__title {
        h4 {
          margin-bottom: 1rem;
        }
      }

      &__description {
        p {
          font-size: 0.9375rem;

          line-height: 1.5rem;
        }
      }
    }
  }

  &--orange {
    .sidebar--location__info__icons__icon,
    .text {
      &:after {
        background-color: color(orange) !important;
      }
    }

    .sidebar--location__social__icon {
      border-color: color(orange) !important;
    }
  }

  &--text {
    padding: 18px;
    width: 100%;
    padding-top: 100px;
    margin-bottom: -80px;
    position: relative;
    top: -80px;

    @include mq('768px', 'min') {
      padding: 100px 40px;
      width: auto;
      margin-bottom: 0px;
      top: 0px;
    }
  }
}