.container {
	@each $breakpoint, $value in $grid-breakpoints {
		@include mq($breakpoint) {
			width: map-get($container-max-widths, $breakpoint)
		}
	}
	
	margin-left: auto;
	margin-right: auto;
	max-width: 100%;
	padding: 0 $gutter/2 + px;

	max-width: 1490px;

	&--fluid-mob {
		@include mq('md', 'max') {
			width: 100%;
		}
	}
}

.spacer {
	padding: $gutter/2 + px 0;
	&--double {
		padding: $gutter/2 + px 0;
		@include mq('md', 'min') {
			padding: $gutter + px 0;
		}
	}
}

.row {
	margin: 0;
	display: flex;
	flex-wrap: wrap;

	// iOS7 Fix
	@include mq('md', 'max') {
		width: 100%;
	    display: -webkit-flex;
	    -webkit-flex-wrap: wrap;
	}

	@include mq('md', 'min') {
		margin-right: - + ($gutter/2) + px;
		margin-left: - + ($gutter/2) + px;
	}

	&--j-sb {
		justify-content: space-between;
	}

	&--j-sa {
		justify-content: space-around;
	}

	&--j-fs {
		justify-content: flex-start;
	}

	&--j-fe {
		justify-content: flex-end;
	}

	&--j-c {
		justify-content: center;
	}

	&--a-c {
		align-items: center;
	}

	&--a-fe {
		align-items: flex-end;
	}
	
	&--align-items {
		&--center {
			align-items: center;
		}
	}

	&--wrap {
		flex-wrap: wrap;
		-webkit-flex-wrap: wrap;
	}

	&--xs-wrap {
		@include mq('xs', 'min') {
			flex-wrap: nowrap;
		}
	}

	&--sm-wrap {
		@include mq('sm', 'min') {
			flex-wrap: nowrap;
		}
	}

	&--md-wrap {
		@include mq('md', 'min') {
			flex-wrap: nowrap;
			-webkit-flex-wrap: nowrap;
		}
		&--lg {
			@include mq('md', 'min') {
				flex-wrap: wrap;
				-webkit-flex-wrap: wrap;
			}
			@include mq('lg', 'min') {
				flex-wrap: nowrap;
				-webkit-flex-wrap: nowrap;
			}
		}
	}

	&--lg-wrap {
		@include mq('lg', 'min') {
			flex-wrap: nowrap;
		}
	}

	// &:before,
	// &:after
	// {
	// 	display: table;
	// 	content: " ";
	// }

	&:after {
		clear: both;
	}
}

.flex {
	margin: 0;
	display: flex;
	flex-wrap: wrap;

	// iOS7 Fix
	@include mq('md', 'max') {
		width: 100%;
	    display: -webkit-flex;
	    -webkit-flex-wrap: wrap;
	}
	
	&--j-sb {
		display: flex;
		justify-content: space-between;
	}

	&--j-sa {
		display: flex;
		justify-content: space-around;
	}

	&--j-fs {
		display: flex;
		justify-content: flex-start;
	}

	&--j-fe {
		display: flex;
		justify-content: flex-end;
	}

	&--j-c {
		display: flex;
		justify-content: center;
	}

	&--a-c {
		display: flex;
		align-items: center;
	}

	&--a-fs {
		align-items: flex-start;
	}

	&--align-items {
		&--center {
			display: flex;
			align-items: center;
		}
	}

	&--wrap {
		display: flex;
		flex-wrap: wrap;
		-webkit-flex-wrap: wrap;
	}

	&--xs-wrap {
		@include mq('xs', 'min') {
			display: flex;
			flex-wrap: nowrap;
		}
	}

	&--sm-wrap {
		@include mq('sm', 'min') {
			display: flex;
			flex-wrap: nowrap;
		}
	}

	&--md-wrap {
		@include mq('md', 'min') {
			display: flex;
			flex-wrap: nowrap;
			-webkit-flex-wrap: nowrap;
		}
		&--lg {
			@include mq('md', 'min') {
				display: flex;
				flex-wrap: wrap;
				-webkit-flex-wrap: wrap;
			}
			@include mq('lg', 'min') {
				display: flex;
				flex-wrap: nowrap;
				-webkit-flex-wrap: nowrap;
			}
		}
	}

	&--lg-wrap {
		@include mq('lg', 'min') {
			display: flex;
			flex-wrap: nowrap;
		}
	}
}

.mob {
	&--list {
		&--nopad {
			ul {
				@include mq('md', 'max') {
					padding-top: 0;
					padding-bottom: 0;
				}
			}
		}
	}
}

[class^="col-"], [class*=" col-"] {
	position: relative;
	min-height: 1px;
	width: 100%;
	padding: $gutter/2 + px 0;

	@include mq('md', 'min') {
		padding: $gutter/2 + px $gutter/4 + px;
	}

	@include mq('lg', 'min') {
		padding: $gutter/3 + px $gutter/4 + px;
	}
}

@each $breakpoint, $value in $grid-breakpoints {
	@include mq(#{$breakpoint}) {
		@for $i from 1 through $grid-size {
			.col-#{$breakpoint}-#{$i} {
				$width: 100 / $grid-size * $i;

				width: ($width - 1) * 1%;
				flex-grow: 1;
			}
		}
	}
}

.col {
	&--no-grow {
		flex-grow: 0;
	}
}
