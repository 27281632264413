$c: '.page-tertiary';

#{$c} {
  background-size: auto !important;

  h1 {
    font-size: 2.5rem;

    @include mq('md', 'min') {
      line-height: 4.063rem;
      font-size: 4.375rem;
      > span {
        font-size: 5.938rem;
        letter-spacing: 7.21x;
      }
    }
  }

  .terms-conditions,
  .privacy-policy,
  .faq {
    margin-top: 20px;

    a,
    a:visited,
    a:hover {
      color: color(red);
      cursor: pointer;
    }

    details {
      margin-bottom: 20px;

      summary {
        font-weight: bold;
        font-size: 1em;
        padding-bottom: .5em;
      }
    }


    .sub-group {
      padding-top: 20px;
      padding-left: 30px;

      details {
        margin-bottom: 1em;

        summary {
          font-weight: bold;
          font-size: 1em;
          padding-bottom: .3em;
        }
      }
    }
  }
}