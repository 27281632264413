$c: '.share';

#{$c} {
	&__list {
		display: flex;
		margin: 0;
		padding: 0;
		justify-content: space-between;
		height: 0;
		transition: $default-timing $default-easing;
		transition-delay: .3s;
		overflow: hidden;
		position: relative;
		z-index: 2;

		&.active {
			height: 60px;
			transition-delay: 0s;
			overflow: visible;
			margin-top: 5px;

			@include mq('378px','max') {
				height: 57px;
			}

			#{$c} {
				&__item {
					opacity: 1;
					visibility: visible;
					transform: none;
				}
			}
		}
	}

	&__item {
		list-style-type: none;
		width: 60px;
		height: 100%;
		margin-right: 4px;
		opacity: 0;
		visibility: hidden;
		transform: translate(0, -20px);
		transition: $default-timing $default-easing;

		@include mq('378px','max') {
			width: 57px;
		}

		&:last-child {
			margin-right: 0;
		}

		p, a {
			color: color(white);
			text-decoration: none;
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 30px;
			margin: 0;
			cursor: pointer;

			@include mq('378px','max') {
				font-size: 27px;
			}

			span {
				transition: $default-timing $default-easing;
			}

			&:hover {
				[class^="icon-"] {
					transform: translate(0, -5px);
				}
			}
		}

		&--facebook {
			background-color: #3b5998;
			transition-delay: 0s;
		}

		&--twitter {
			background-color: #00aced;
			transition-delay: .1s;
		}

		&--whatsapp {
			background-color: #34af23;
			transition-delay: .2s;
		}

		&--email {
			background-color: #ff543a;
			transition-delay: .3s;

			a {
				font-size: 35px;
			}
		}

		&--link {
			background-color: #ecb740;
			transition-delay: .4s;
		}
	}
}