.countdown-banner {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include mq('md', 'min') {
    flex-direction: row;
    justify-content: space-evenly;
  }

  #end_message {
    display: none;
    margin-top: 20px;
  }
  .banner-text {
    h1,h2,h3,h4,h5,h6 {
      margin-bottom: 16px;
    }
  }

  .banner-text,
  #end_message {
    text-align: center;
  }

  #timer {
    display: flex;
    gap: 5px;
    justify-content: space-between;
    align-items: center;

    @include mq('md', 'max') {
      margin-top: 20px;
    }

    .c-days,
    .c-hours,
    .c-minutes,
    .c-seconds {
      position: relative;
      padding: 10px;
      border: 3px solid #fff;
      border-radius: 5px;
      min-width: 45px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .c-days::after,
    .c-hours::after,
    .c-minutes::after,
    .c-seconds::after {
      position: absolute;
      bottom: -20px;
      font-size: 12px;
    }
    .c-days::after {
      content: 'Days';
    }
    .c-hours::after {
      content: 'Hours'
    }
    .c-minutes::after {
      content: 'Minutes';
    }
    .c-seconds::after {
      content: 'Seconds';
    }
  }

  .cta {
    @include mq('md', 'max') {
      margin-top: 40px;
    }
  }
}