$c: '.article-offer';

#{$c} {
	display: flex;

	&__container {
		position: relative;
//		padding: 35px;
		display: flex;
		flex-direction: column;
		align-items: center;
		background-size: cover;
		background-repeat: no-repeat;
		width: 100%;
		.ie.v-11 & {
			display: block;
		}
	}

	&__foreground {
		margin-bottom: 25px;
	}

	.button {
		margin-top: auto;
		position: absolute;
    	bottom: 40px;
    	left: 50%;
    	transform: translateX(-50%);
    	@include mq('768px','max') {
    		width: calc(100% - 70px);
    	}

	}
}