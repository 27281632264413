$c: '.tweet';

#{$c} {
	display: flex;
	.icon-twitter {
		position: absolute;
		right: 24px;
		top: 29px;
		color: #55ADEE;
		font-size: 24px;
	}

	&__container {
		max-width: 100%;
		// height: 100%;
		background-color: color(white);
		box-shadow: 0px 0px 36px 0px rgba(0,0,0,.28);
		display: flex;
		flex-direction: column;
	}

	&__header {
		display: flex;
		flex-direction: column;
		padding: 20px;

		@include mq('768px','min') {
			flex-direction: row;
		}

		&__image {
			min-width: 50px;
			min-height: 50px;
			max-width: 50px;
			max-height: 50px;
			align-self: center;
			margin-bottom: 20px;

			@include mq('768px','min') {
				min-width: 80px;
				min-height: 80px;
				max-width: 80px;
				max-height: 80px;
				margin-bottom: 0;
				margin-right: 20px;
			}

			img {
				border-radius: 100%;
			}
		}

		&__content {
			padding-right: 30px;
		}

		&__meta {
			display: flex;
			margin-bottom: 10px;

			&--name {
				font-weight: bold;
				margin: 0;
				margin-right: 10px;
				// font-size: 17px;
			}

			&--handle, &--time {
				margin: 0;
				opacity: .5;
				// font-size: 17px;
			}

			&--time {
				&:before {
					display: inline;
					content: '•';
					position: relative;
					margin: 0 5px;
				}
			}
		}

		&__tweet {
			opacity: .5;

			a {
				color: inherit;
				text-decoration: none;

				&:hover {
					text-decoration: underline;
				}
			}

			&--large {
				padding: 20px;
				opacity: 1;
			}
		}
	}

	&__media {
		margin-top: auto;
	}
}