$c: '.spotify';

#{$c} {
	display: flex;
	// flex-direction: column;

	iframe {
		width: 100%;
		height: auto;
	}
}