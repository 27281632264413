$c: '.single-bar';

#{$c} {
  &__conveyor, &__news {
    padding: 38px 0 0;
    background-color: #fff;
  }

  &__news {
    .single-bar__title {
      span {
        text-transform: uppercase;
        text-align: center;
      }
    }
  }

  &__conveyor {
    .owl-item {
      border-radius: 3px;

      > * {
        background-color: color(navy);
      }
    }

    .conveyor-item__container {
      padding: 0;

      .button {
        position: absolute;
        bottom: 35px;
      }
    }
  }

  &__intro {
    display: flex;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 0;

    @include mq('md', 'min') {
      padding-top: 60px;
      padding-bottom: 30px;
    }
    @include mq('1400px', 'min') {
      padding-top: 0px;
    }

    @include mq('md', 'max') {
      order: 2;
      padding-top: 20px;
      padding-bottom: 30px;
    }

    &__container {
      @include mq('768px', 'min') {
        display: flex;
        align-items: center;
      }

      > .row {
        width: 100%;
        display: block;

        @include mq('768px', 'min') {
          display: flex;
          flex-direction: row;
        }
      }
    }

    &__left {
      flex-basis: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__right {
      flex-basis: 50%;

      p {
        max-width: 600px;
        font-size: 16px;
        line-height: 24px;
        @include mq('768px', 'min') {
          line-height: 31px;
        }

        &:first-child {
          @include mq('768px', 'min') {
            margin-top: 0;
          }
        }

        &:last-child {
          @include mq('768px', 'min') {
            margin-bottom: 0;
          }
        }

        strong {
          font-size: 25px;
          line-height: 26px;
        }
      }
    }

    &__title {
      text-align: center;
      position: relative;
      top: 0;

      @include mq('500px', 'max') {
        font-size: 2rem;
      }
      span {
        text-transform: uppercase;
        text-align: center;
      }
    }
  }

  &__fiesta {
    @include mq('md', 'min') {
      padding: 70px 0 100px;
    }

    > .container {
      max-width: 100%;
      padding: 0;

      @include mq('md', 'min') {
        max-width: 1688px;
        padding: 0 25px;
      }
    }

    &__container {
      padding-top: 0;
      display: flex;
      flex-direction: column;

      @include mq('768px', 'min') {

      }

      @include mq('1024px', 'min') {
        flex-direction: row;
        padding-top: 25px;
        justify-content: center;
        align-items: flex-start;
      }
    }
  }

  &__title {
    margin: 0;
    display: inline-block;
    text-transform: uppercase;

    &--bottom {
      margin-bottom: 24px;
      @include mq('md', 'min') {
        margin-bottom: 56px;
      }
    }
  }
}