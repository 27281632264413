$c: '.christmas';

#{$c} {
	&__sidebar {
		@extend .sidebar--location;
		max-width: 90%;
		margin: 0 auto;
		display: block;
		width: 470px;

		@include mq('md','min') 
		{
			margin-left: auto;
			margin-right: 0;
		}
		@include mq('1250px','min') {
			width: 500px;
		}

		&__container {
			@extend .gallery__sidebar;
			padding: 0;
			
			@include mq('md','min') {
		    transform: translateY(-50%);
		  }
		}

		&__times {
			&__container {
				@extend .sidebar--location__block2;

				hr {
					display: block;
				}
			}

			&__section {
				@extend .sidebar--location__times__section;
			}
		}

		&__social {
			@extend .sidebar--location__social;

			@include mq('md','max') {
				padding-top: 0;
			}

			&__container {
				@extend .sidebar--location__block3;
			}

			&__icon {
				@extend .sidebar--location__social__icon;
			}
		}

		&__address {
			position: relative;
			text-align: center;

			@include mq('md','min') {
				text-align: left;
			}

			&__container {
				padding-left: 32px;
				padding-right: 32px;

				@include mq('md','min') {
					padding-left: 32px;
					padding-right: 0px;
				}
			}

			&__address {
				font-weight: bold;
				font-size: 22px;
				margin-top: 0;

				span {
					position: absolute;
					left: -32px;
					font-size: 32px;
					color: color(fuschia);

					@include mq('md','max') {
						display: block;
						position: static;
					}
				}
			}

			&__phone {
				font-size: 22px;
				margin-bottom: 0;
			}
		}

		&__share
		{
			margin-top: 1em;

			@include mq('md','max') 
			{
				display: none;
			}
		}
	}
}