$c: '.featured-cta';

$featured-base-time: 450ms;
$featured-base-delay: 75ms;

#{$c} {
	position: relative;
	display: flex;
	flex-direction: column;
	
	&__image {
		.img-desktop {
			display: none;
		}
		width: 100% !important;
		left: 0 !important;

		@include mq('900px', 'min') {
			.img-desktop {
				display: block;
				height: 100vh !important;
			}
			.img-mobile {
				display: none;
				width: 100% !important;
			}
		}
	}

	&.hasAnim {
	
		&#{$c} {
			&--right {
				#{$c} {
					&__image {
						@include mq('700px','min') {
							margin-left: auto;
							margin-right: 0;
						}
					}
					&__tag {
						transform: translate(40px, 0px);
					}
				}
			}
		}
	
		#{$c} {
			
			&__image {
				width: 0%;
				margin-left: 0;
				overflow: hidden;

				img {
					object-fit: cover;
				}
			}
			&__tag {
				opacity: 0;
				transform: translate(-40px, 0px);
			}
			&__sidebar {
				opacity: 0;
			}
		}


		&.inview {
			#{$c} {
				&__image {
					width: 100%;
					@include mq('700px','max') {
						width: 80%;
					    left: 10%;
						z-index: 4;
					}
				}
				&__tag {
					opacity: 1;
					transform: translate(0px, 0px);
					@include mq('700px','max') {
						transform: translate(-50%, 0);
					}
				}
				&__sidebar {
					z-index: 3;
					opacity: 1;

					@include mq('700px', 'min') {
						height: 100%;
					}

					@media only screen and (min-device-width: 700px) and (max-device-width: 900px) {
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%)
					}
				}
			}
		}

	}
	
	&__container {
			padding-top: 50px;
			padding-bottom: 50px;
			
			@include mq('700px', 'min') {
			padding-top: 100px;
			padding-bottom: 100px;
			margin-top: 0 !important;
		}

		@include mq('1024px','min') {
			padding-top: 13vh !important;
			padding-bottom: 13vh !important;
		}
	}

	&__tag {
		position: absolute;
		top: -40px;
		right: 0;
		z-index: 3;
	}

	&__sidebar {
		order: 2;

		@include mq('700px','min') {
			order: 1;
			position: absolute;
			top: 50%;
			transform: translate(0, -50%);
			z-index: 2;
		}

		.sidebar--text {
			@include mq('700px','min') {
				padding: 40px;
				max-width: 500px;
				width: 500px;
				height: 100%;

				@include mq('1100px', 'max') {
					padding: 20px;
				}
			}
		}

		.sidebar__title {
			padding-bottom: 20px;
			text-align: center;

			@include mq('700px','max') {
				text-align: center;

				h4 {
					font-size: 32px;
					margin-bottom: 0;
				}
			}

			@include mq('700px','min') {
				h4 {
					margin-bottom: 10px;
				}
			}
		}

		.sidebar__description {
			margin: 0 auto;

			@include mq('500px', 'min'){
				max-width: 90%;
			}
		}
	}

	&__image { 
		order: 1;
		overflow: hidden;
		width: 80%;
		background-position: center center;
		background-size: cover;
		margin: 0 auto;
		position: relative;
		z-index: 2;

		@include mq('700px','min') {
			background-image: none !important;
			order: 2;
			width: auto;
			height: auto;
			padding-bottom: 0; 
			z-index: 1;
		}
	}

	&--right {
		#{$c} {
			&__sidebar {
				@include mq('900px', 'min') {
					right: 0;
				}
			}
			&__image {
				img {
					@include mq('700px','min') {
						left: -100px;
						right: auto;
					}
				}
			}
		}
	}
}

.with-cta {
	.featured-cta__sidebar{
		.sidebar--text {
			@include mq('700px','min') {
				top: 50%;
				transform: translate(0%, -50%);
				height: fit-content;
			}
			
			@include mq('900px', 'min') {
				height: 100%;
				display: flex;
				flex-direction: column;
				justify-content: space-around;
			}
		}
		.sidebar__button {
			padding-top: 20px;

			@include mq('900px', 'min') {
				margin-top: 0;
			}
		}	
	}
}