footer {
  background-color: color(rdc-background);
  color: #fff;

  .sign-up {
    background-color: color(rdc-green);
    background-image: url(../../app/img/bg/green-bg.png);
    background-size: cover;
    padding: 60px 150px;
    color: #fff;

    @include mq('991px', 'max') {
      padding: 40px 30px;
    }

    .heading {
      color: #fff;
      margin: 0 0 20px;
      @include mq('991px', 'max') {
        display: none;
      }
    }

    .smallcopy {
      margin-bottom: 40px;
      @include mq('991px', 'max') {
        display: none;
      }
    }

    .title {
      text-align: center;

      h4 {
        margin-bottom: 8px;
      }
    }

    .mobile-accordian {
      margin-bottom: 8px;
      justify-content: space-around;
    }

    .sign-up-accordion {
      display: flex;
      flex-direction: column;
      align-items: center;

      @include mq('991px', 'max') {
        &:not(.open) {
          display: none;
        }
      }

      .gravity-form {
        @include mq('991px', 'max') {
          margin-top: 40px;
        }

        // fix for invisible gravity form fields that take 100% width and breaking the layout
        * {
          .gform_hidden {
            display: none;
          }
        }

        .gform_hidden {
          display: none;
        }

        form {
          // form inputs
          ul {
            li {
              width: 33.33%;
              @include mq('991px', 'max') {
                width: 100%;
              }

              &:nth-last-child(-n+2) {
                width: 100%;
              }

              label {
                font-size: 16px;
              }

              label, input, input::placeholder {
                color: #fff;
                font-weight: normal;
              }

              input {
                background-color: color(rdc-green);
                border: 1px solid #fff;
              }

              // date of birth picker
              .ginput_container_date {
                .clear-multi {
                  display: flex;
                  justify-content: space-between;

                  div {
                    width: 30%;

                    input {
                      width: 100%;
                      font-family: inherit;
                      display: block;
                      font-size: inherit;
                      line-height: 3.375em;
                      height: 3.375em;
                      padding: 0 1em;
                      border-radius: 2px;
                      outline: none;
                      margin-bottom: 8px;
                    }
                  }
                }
              }

              // label links
              a {
                color: color(font);
                cursor: pointer;
              }

              // location
              &.gfield--name--choose-your-revolucion-de-cuba {
                display: flex;
                align-items: flex-end;
                gap: 20px;

                @include mq('991px', 'max') {
                  flex-direction: column;
                  .gfield_label {
                    width: 100%;
                  }
                }

                .ginput_container {
                  width: 40%;

                  @include mq('991px', 'max') {
                    width: 100%;
                  }

                  .selectric {
                    border-bottom: 2px solid #fff;

                    .label::after,
                    .label {
                      color: #fff;
                    }
                  }

                  .selectric-items {
                    li {
                      width: 100%;
                    }
                  }
                }
              }

              // consent
              &.consent {
                label {
                  font-size: 14px;
                }

                .ginput_container label::before {
                  border-color: #4ca586;
                  background-color: #fff;
                  color: color(rdc-red);
                }

                &:hover {
                  .ginput_container label::before {
                    border-color: color(rdc-red);
                    background-color: #fff;
                  }
                }
              }
            }
          }

          // form footer
          .gform_footer {
            margin: 10px 0 0;
            display: flex;
            justify-content: center;

            button {
              border: none;
              background-color: color(rdc-primary);
              padding: 10px 20px;
              color: #fff;
            }
          }
        }
      }

      // confirmation text
      .gravity-form--footer_signup {
        .gform_confirmation_wrapper {
          .gform_confirmation_message {
            border: none;

            span {
              color: #fff;
              font-size: 1.5rem;
            }
          }
        }
      }
    }
  }

  .footer__container {
    * {
      color: #fff;
    }

    background-color: color(rdc-primary);
    padding: 40px 150px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;

    @include mq('991px', 'max') {
      padding: 40px 50px;
    }
    @include mq('850px', 'max') {
      flex-direction: column;
      align-items: center;
    }

    .heading {
      font-size: 1rem;
      margin: 16px 0;
      text-transform: uppercase;
      text-align: center;
    }

    .logo {
      width: 100%;
      margin-bottom: 20px;

      @include mq('850px', 'max') {
        width: fit-content;
      }

      img {
        width: 250px;
      }
    }


    .footer-links {
      list-style: none;
      margin-top: 16px;

      @include mq('850px', 'max') {
        margin: 16px 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        li {
          width: 50%;
          max-width: 250px;
          text-align: center;
        }
      }

      li {
        margin-bottom: 8px;

        h6 {
          margin: 0;
          text-transform: uppercase;
          font-size: 1.5rem;
        }

        a {
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    .policy, .socials-wrapper {
      .heading {
        font-size: 1.5rem;
      }
    }

    .policy {
      ul {
        list-style: none;
        padding: 0;

        @include mq('850px', 'max') {
          margin: 16px 0;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;

          li {
            text-align: center;
            margin-right: 8px;
            margin-left: 8px;

            &:last-of-type {
              width: 100%;
            }
          }
        }

        li {
          font-size: .85rem;
          margin-bottom: 8px;

          a {
            text-decoration: none;
            cursor: pointer;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }

    .socials-wrapper {
      .list--social {
        li {
          font-size: 1.5rem;
          cursor: pointer;
          padding: 0 20px 0 0;

          &:last-of-type {
            padding: 0;
          }

          a {
            text-decoration: none;
          }
        }
      }
    }
  }
}