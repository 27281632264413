$c: '.single';

#{$c} {
	
	.content {
		
		h1, h2, h3, h4, h5, h6 {
			font-family: $body-font-family;
			font-weight: bold;
			margin-top: 2rem;
			margin-bottom: 1rem;
			line-height: 1.2;
		}
		h1, h2 {
			font-size: 2em;
		}
		h3 {
			font-size: 1.5em;
		}
		h4 {
			font-size: 1.25em;
		}
		h5 {
			font-size: 1em;
		}
		p
		{
			margin-top: 0;
		}
		p, li {
			color: color(navy);
			line-height: 31px;
			a {
			    color: inherit;
			    font-weight: 800;
			    color: color(green);
			}
		}
		iframe {
			@include mq('600px','max') {
				max-width: 100% !important;

				// Not sure what iframe this targets but it breaks Instagram embeds so exclude them at least
				&:not(.instagram-media)
				{
	    		height: auto;
	    	}

	    	// Fix instragram embeds not respecting relative widths on iOS
				&.instagram-media
				{
	    		width: 600px !important;	// (Our breakpoint so equivalent to 100%)
	    	}
	    }
		}

		img {
			height: auto;
		}
		/* == WordPress WYSIWYG Editor Styles == */
		.entry-content img {
			margin: 0 0 1.5em 0;
			}
		.alignleft, img.alignleft {
			margin-right: 1.5em;
			display: inline;
			float: left;
			}
		.alignright, img.alignright {
			margin-left: 1.5em;
			display: inline;
			float: right;
			}
		.aligncenter, img.aligncenter {
			margin-right: auto;
			margin-left: auto;
			display: block;
			clear: both;
			}
		.wp-caption {
			margin-bottom: 1.5em;
			text-align: center;
			padding-top: 5px;
			max-width: 100%;
			}
		.wp-caption img {
			border: 0 none;
			padding: 0;
			margin: 0;
			}
		.wp-caption p.wp-caption-text {
			line-height: 1.5;
			font-size: 10px;
			margin: 0;
			}
		.wp-smiley {
			margin: 0 !important;
			max-height: 1em;
			}
		blockquote.left {
			margin-right: 20px;
			text-align: right;
			margin-left: 0;
			width: 33%;
			float: left;
			}
		blockquote.right {
			margin-left: 20px;
			text-align: left;
			margin-right: 0;
			width: 33%;
			float: right;
			}
		/*.gallery dl {}
		.gallery dt {}
		.gallery dd {}
		.gallery dl a {}
		.gallery dl img {}
		.gallery-caption {}

		.size-full {}
		.size-large {}
		.size-medium {}
		.size-thumbnail {}*/
	}
}