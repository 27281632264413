.packages {
  background-color: color(rdc-pink);
  background-image: url(../../app/img/bg/red-bg.png);
  background-size: cover;
  padding: 40px 0;

  &.has-title {
    padding-top: 0;
  }

  .owl-stage {
    display: flex;

    .owl-item {
      flex: 1;
      margin: 0 10px;
    }
  }

  &__wrapper {
    width: 90%;
    margin: 0 auto;
  }

  &__heading {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
    padding: 60px 0;

    @include mq('1200px', 'max') {
      padding: 40px 0;
    }

    .packages__title {
      margin: 0;
      text-align: center;

      @include mq('700px','max') {
        font-size: 1.7rem;
      }
    }

    &_flair {
      color: color(rdc-primary);
      text-transform: uppercase;
      font-weight: bold;
      font-size: 24px;
      text-align: center;
    }
  }

  &__container {
    .package {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &__image {
        height: 275px;
        background-position: 50% 50%;
        background-size: cover;
        background-repeat: no-repeat;
        transform: translate3d(0px, 0px, 0px);
        flex: none;
        position: relative;

        &__backdrop {
          position: absolute;
          height: 100%;
          width: 100%;
          top: 0;
          left: 0;
          background-color: rgba(0, 0, 0, 0.2);
        }

        p {
          margin: 0;
        }

        .package__tags {
          display: flex;
          justify-content: space-between;
          z-index: 1;
          position: relative;

          .package__popular, .package__tag {
            width: fit-content;
            padding: 6px 10px;
            font-weight: bold;
            color: #fff;
            font-family: $body-font-family;
          }

          .package__popular {
            background-color: color(rdc-red);
            margin-left: auto;
            border-radius: 0 0 0 4px;
            text-transform: uppercase;
            letter-spacing: -0.5px;
          }

          .package__tag {
            background-color: color(rdc-primary);
            border-radius: 0 0 4px 0;
            margin-right: auto;
          }

          a {
            text-decoration: none;
            cursor: default;
          }
        }
      }

      &__content {
        background-color: color(rdc-background);
        height: 100%;
        padding: 10px 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #000;

        @include mq('750px', 'max') {
          padding: 10px 15px;
        }

        .package-flair {
          margin: 0;
          text-transform: uppercase;
        }

        h3 {
          text-align: center;
          margin: 16px 0;
          font-size: 2rem;

          &.has-flair {
            margin-top: 0;
          }
        }

        p {
          margin: 8px 0;
          text-align: center;
        }
      }

      .button {
        flex: none;
        max-width: unset;
        background-color: color(rdc-primary);
        margin-top: 20px;

        span {
          text-transform: uppercase;
        }
      }
    }
  }

  &__nav {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    align-items: center;

    &__prev, &__center, &__next {
      cursor: pointer;
    }

    &__center {
      width: 10px;
      height: 10px;
      background: color(rdc-primary);
      margin: 0 10px;
      border-radius: 10px;
    }
  }

  &__button {
    margin: 40px 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .button {
      background-color: color(rdc-green);
    }
  }

  &__small_print {
    color: #fff;
    font-size: 1rem;
  }

}
