.hero {
  min-height: 100%;
  height: fit-content;
  width: 100%;
  background-color: color(rdc-background);

  .hero__slide {
    min-height: 100vh;
    position: relative;
    display: flex;
    align-items: center;

    .hero__slide__bg {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      overflow: hidden;
      height: 100%;

      .hero__slide__bg__tint {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 3;
      }

      .hero-leaf {
        height: 100%;
        width: 100%;
        background-repeat: no-repeat;
        position: absolute;
        z-index: 3;

        @include mq('1000px', 'max') {
          display: none;
        }

        &.leaf-navy-1 {
          background-image: url(../../app/img/bg/leaf-navy-1.png);
          background-size: 500px !important;
          background-position: bottom -270px left -220px;
        }

        &.leaf-navy-2 {
          background-image: url(../../app/img/bg/leaf-navy-4.png);
          background-size: 500px !important;
          background-position: bottom -250px left -140px;
        }

        &.leaf-navy-3 {
          background-image: url(../../app/img/bg/leaf-navy-3.png);
          background-size: 500px !important;
          background-position: top -300px right -320px;
        }

        &.leaf-navy-4 {
          background-image: url(../../app/img/bg/leaf-navy-2.png);
          background-size: 500px !important;
          background-position: top -120px right -120px;
        }
      }


      video {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        min-width: 100%;
        min-height: 100%;
        width: auto;
        height: auto;
        z-index: 2;
      }

      &-desktop, &-mobile {
        height: 100%;
        background-position: 50% 50%;
        background-size: cover;
        background-repeat: no-repeat;
        transform: translate3d(0px, 0px, 0px);
      }

      &-desktop {
        display: none;
      }

      @include mq('730px', 'min') {
        &-desktop {
          display: block;
        }
        &-mobile {
          display: none;
        }
      }
    }

    .hero__slide__content {
      height: fit-content;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &-wrapper {
        position: relative;
        z-index: 4;
        width: 100%;
        display: flex;
        justify-content: center;

        .hero__slide__content__content {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 70%;
          padding: 0 50px;

          @include mq('1600px', 'min') {
            width: 50%;
          }

          @include mq('880px', 'max') {
            width: 100%;
          }

          .hero-title-wrapper {
            .hero__slide__content__flair {
              width: 100%;
              text-align: center;
              margin: 0;
              font-size: 24px;
              text-transform: uppercase;

              &.flair--bold-text {
                font-weight: bold;
              }
            }

            .hero__slide__content__title {
              text-transform: uppercase;
              text-align: center;
              font-weight: bold;
              margin-top: 0;
              font-size: 3rem;

              @include mq('880px', 'max') {
                font-size: 2.25rem;
              }
            }
          }

          .hero__slide__content__synopsys {
            text-align: center;

            a {
              color: #fff;
              text-decoration: none;
              border-bottom: 1px solid #fff;
              cursor: pointer;
            }
          }

          .hero-buttons {
            display: flex;
            justify-content: center;
            flex-direction: column;

            &.menu-page {
              justify-content: flex-start;
              margin-top: 30px;

              .button {
                justify-content: flex-start;
              }

              @include mq('900px', 'max') {
                align-content: center;
                margin: 0 auto;

                .button {
                  justify-content: center;
                }
              }
            }

            .hero__slide__content__button {
              margin-top: 60px;
              padding: 14px 50px;
              position: relative;
              display: block;
              width: fit-content;
              line-height: 50px;
              text-align: center;

              span {
                display: inline-block;
                width: fit-content;
                border: none;
                font-weight: bold;
                text-transform: uppercase;
                color: #000;
                padding: 0;
              }

              &.border:before,
              &.border:after {
                background: color(rdc-pink);
              }

              &:before, &:after {
                position: absolute;
                content: '';
                width: -webkit-fill-available;
                left: 0;
                height: 25px;
                z-index: -1;
              }

              &:before {
                transform: perspective(10px) rotateX(3deg);
              }

              &:after {
                top: 40px;
                transform: perspective(10px) rotateX(-3deg);
              }

              &.border:before,
              &.border:after {
                border: 4px solid color(rdc-pink);
              }

              // Border hover styles
              &.border:hover:before,
              &.border:hover:after {
                border-color: color(rdc-red);
              }

              &.border:before {
                border-bottom: none; /* to prevent the border-line showing up in the middle of the shape */
              }

              &.border:after {
                border-top: none; /* to prevent the border-line showing up in the middle of the shape */
              }
            }

            .hero__slide__content__button-location {
              margin-top: 10px;
              text-transform: none;
              color: color(rdc-red);
              font-weight: bold;
              cursor: pointer;
              display: flex;
              justify-content: center;

              span {
                border-color: color(rdc-red);
              }
            }

            // location address
            .hero__slide__content__content__address {
              margin-top: 20px;
            }
          }
        }
      }

      // hero with form
      &.has-form {
        height: fit-content;
        flex-direction: row;
        justify-content: space-around;

        * {
          text-align: left !important;
        }

        .hero__slide__content-wrapper {
          width: 40%;

          .hero__slide__content__content {
            width: 100%;
          }
        }

        .hero-title-wrapper, .hero-buttons {
          margin-left: 0;
          margin-right: auto;
        }

        .form-wrapper {
          padding: 40px 0;
          width: 42%;
        }

        @include mq('900px', 'max') {
          flex-direction: column;

          * {
            text-align: center !important;

            p {
              font-size: 16px;
            }
          }

          .hero-title-wrapper {
            margin-right: 0;
          }

          .hero__slide__content__content {
            padding: 0 25px;
          }

          .hero__slide__content-wrapper, .form-wrapper {
            width: 90%;
          }
        }

        // gravity styles
        .gravity-form--widget {
          .gravity-form__title, .gform_footer, .gform_submit span {
            text-align: center !important;
          }
        }
      }
    }
  }

  .owl-nav {
    z-index: 10;
    position: absolute;
    bottom: 15px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;

    @include mq('lg', 'max') {
      display: none;
    }

    .owl-prev, .owl-next {
      width: 30px;
      height: 20px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      position: relative;
      cursor: pointer;

      span {
        font-size: 32px;
        z-index: 1;
        color: #D6D6D6;

        &:hover {
          color: #869791;
        }
      }
    }
  }
}