.fixr_container {
    background-color: #fff;
    padding-top: 120px;
    padding-bottom: 100px;

    @include mq('1000px', 'max') {
        padding-top: 100px;
    }

    @include mq('699px', 'max') {
        padding-top: 150px;
        
        .fixr-events-widget__daypicker {
            margin-top: 40px;
        }
    }

    @include mq('900px', 'min') {
        .fixr-events-widget__header {
            margin-bottom: 50px;
        }
        
        .fixr-event-item {
            &__price {
                font-size: 20px;
                letter-spacing: .5px;
            }
        }
    }
   
    .contents-wrapper {
        transform: translate(-50%, -10%) !important;
        margin-top: 10px;
    }

}