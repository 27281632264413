//colors
$color_alto: #ddd;
$color_desert_storm: #f8f8f8;
$color_tundora: #444;
$color_pink_swan: #bbb;
$color_silver_chalice: #aaa;
$color_cloud: #c4c4c4;
$color_delta: #a2a2a2;
$color_storm_dust: #666;
$color_bon_jour: #e0e0e0;
$color_celeste: #d0d0d0;
$color_quill_gray: #d5d5d5;

//@extend-elements
//original selectors
//.selectric-items ul, .selectric-items li
%extend_1 {
	list-style: none;
	padding: 0;
	margin: 0;
	font-size: 12px;
	line-height: 20px;
	min-height: 20px;
}


.selectric-wrapper {
	position: relative;
	cursor: pointer;
}
.selectric-responsive {
	width: 100%;
}
.selectric {
	position: relative;
	.label {
		display: block;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		user-select: none;
	}
}
.selectric-open {
	z-index: 8;

	.selectric-items {
		display: block;
	}
}
.selectric-disabled {
	filter: alpha(opacity=50);
	opacity: 0.5;
	cursor: default;
	user-select: none;
}
.selectric-hide-select {
	position: relative;
	overflow: hidden;
	width: 0;
	height: 0;
	select {
		position: absolute;
		left: -100%;
	}
	&.selectric-is-native {
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: 10;
		select {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			height: 100%;
			width: 100%;
			border: none;
			z-index: 1;
			box-sizing: border-box;
			opacity: 0;
		}
	}
}
.selectric-input {
	position: absolute !important;
	top: 0 !important;
	left: 0 !important;
	overflow: hidden !important;
	clip: rect(0, 0, 0, 0) !important;
	margin: 0 !important;
	padding: 0 !important;
	width: 1px !important;
	height: 1px !important;
	outline: none !important;
	border: none !important;
	*font: 0/0 a !important;
	background: none !important;
}
.selectric-temp-show {
	position: absolute !important;
	visibility: hidden !important;
	display: block !important;
}
.selectric-items {
	display: none;
	position: absolute;
	top: 100%;
	left: 0;
	z-index: -1;
	box-shadow: 0 0 10px -6px;
	.selectric-scroll {
		height: 100%;
		overflow: auto;
	}
	ul {
		@extend %extend_1;
	}
	li {
		@extend %extend_1;
		display: block;
		padding: 10px;
		cursor: pointer;
	}
	.disabled {
		filter: alpha(opacity=50);
		opacity: 0.5;
		cursor: default !important;
		background: none !important;
		color: $color_storm_dust !important;
		user-select: none;
	}
	.selectric-group {
		.selectric-group-label {
			font-weight: bold;
			padding-left: 10px;
			cursor: default;
			user-select: none;
			background: none;
		}
		li {
			padding-left: 25px;
		}
		&.disabled li {
			filter: alpha(opacity=100);
			opacity: 1;
		}
	}
}

.selectric-above .selectric-items {
	top: auto;
	bottom: 100%;
}