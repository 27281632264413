$c: ".testimonials";

.owl-drag {
  .owl-item {
    cursor: grab;
  }
}

#{$c} {

  &__wrapper {
    max-width: 1920px;
    padding: 60px 0 40px;
    margin-left: 0;
    width: 100vw;
    overflow-x: hidden;
  }

  &__title__wrapper {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  &__title {
    font-family: $header-font-family;
    font-size: 60px;
    line-height: 28px;
    text-align: center;
    color: #ffffff;
    position: relative;
    z-index: 1;
    @include mq("1125px", "max") {
      font-size: 50px;
    }

  }

  &__subtitle {
    font-family: $header-font-family;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #ffffff;
    position: relative;
    z-index: 1;
    width: 100%;
    margin-bottom: 30px;


  }

  &-dots {
    margin-left: 10%;
    display: flex;
    justify-content: flex-start;
    @include mq("479px", "max") {
      margin-left: 7%;
    }
  }
  &__name{
    font-size: 18px;
  }

  &__underline {
    background-image: url(../../app/img/tags/Underlinee.svg);
    position: absolute;
    left: -22px;
    bottom: -23px;
    background-repeat: no-repeat;
    background-size: contain;
    width: 112%;
    height: 29px;
    z-index: -1;
    @include mq("479px", "max") {
      width: 200px;
    }
  }
}

.bg--christmas {
  overflow-x: hidden;
}

.testimonial__slider {
  &__bg {
    background-size: auto;
    background-image: url(../../app/img/tmp/gallery/ch_fiesta_slider_bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    overflow-x: hidden;
  }
  &__wrapper {
    margin-right: 0;
    padding-right: 0;
    margin-left: 0;
    max-width: 1920px;
    width: 100%;
    @include mq("681px", "max") {
      padding-right: 25px;
    }

    #slider_item_img {
      @include mq("681px", "max") {
        width: 100%;
        height: 269px;
        object-fit: cover;
      }
    }
  }
  &-dots {
    margin-left: 10%;
    display: flex;
    justify-content: flex-start;
    @include mq("479px", "max") {
      margin-left: 7%;
    }
  }

  &__title__wrapper {
    display: flex;
    justify-content: center;
    padding-top: 75px;
    @include mq("578px", "max") {
      padding-top: 51px;
    }
  }

  &__title {
    width: 30%;
    font-family: $header-font-family;
    font-style: normal;
    font-weight: normal;
    font-size: 80px;
    line-height: 65px;
    text-align: center;
    text-transform: uppercase;
    color: #000000;
    position: relative;
    z-index: 1;
    @include mq("1612px", "max") {
      width: 37%;
    }
    @include mq("1366px", "max") {
      width: 410px;
      font-size: 60px;
      line-height: 50px;
    }
    @include mq("578px", "max") {
      width: 60%;
      font-size: 24px;
      line-height: 28px;
    }
  }
  &__underline {
    background-image: url(../../app/img/tags/Underlinee.svg);
    position: absolute;
    left: -22px;
    bottom: -8px;
    background-repeat: no-repeat;
    background-size: contain;
    width: 112%;
    height: 29px;
    z-index: -1;
    @include mq("1366px", "max") {
      bottom: -17px;
    }
    @include mq("479px", "max") {
      left: -12px;
    }
  }
}
